import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
} from "react-router-dom";

import "./styles/normalize.css";
import Home from "./components/Home";
import i18n from "./i18n/i18n";
import Loading from "./components/common/Loading";
import { setAPIBaseUrl } from "./apis/API";
import * as ConfigAPI from "./apis/ConfigAPI";
import Category from "./components/Category";
import ProductDetail from "./components/ProductDetail";
import * as GetIpAPI from "./apis/GetIpAPI";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import Order from "./components/Order";
import OrderDetail from "./components/OrderDetail";
import Fail from "./components/Fail";
import Success from "./components/Success";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ShippingPolicy from "./components/ShippingPolicy";
import * as PixelAPI from "./apis/PixelAPI";
import * as FackbookAPI from "./apis/FacebookAPI";
import queryString from "query-string";
import Popup from "./components/common/Popup";

window.globalRouter = {
  routeShip: "/ship",
  routePrivacy: "/privacy",
  routeWishList: "/wishlist",
  routeCheckout: "/checkout",
  routeShop: "/shop",
  routeAbout: "/about",
  routeLogin: "/login",
  routeOrder: "/order",
  routeProductDetail: "/product-detail",
  routeCart: "/cart",
  routeOrderDetail: "/orderDetail",
  routeCategory: "/category",
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { showLoading: false };
    this.toggleLoading = this.toggleLoading.bind(this);
    this.setRem = this.setRem.bind(this);
  }

  componentDidMount() {
    window.onresize = this.setRem;
    if (window.location.host.indexOf("localhost") < 0) {
      const url = window.location.protocol + "//" + window.location.host;
      setAPIBaseUrl(url);
    }
    this.toggleLoading();
    ConfigAPI.getConfig(
      "routeHome,routeShop,routeCart,routeWishList,routeCheckout,routeAccount,routeAddress,routeProductDetail,routeLogin,routeAbout,routeShip,routeCategory,routePrivacy,routeOrder,routeOrderDetail,icon,shopName,pixelId"
    )
      .then((res) => {
        const data = res.data.data;
        if (data && data.pixelId) {
          const pixelId = data.pixelId;
          let head = document.querySelector("head");
          let script = document.createElement("script");
          script.type = "text/javascript";
          script.innerHTML = `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
              n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');`;

          head.appendChild(script);
          let noscript = document.createElement("noscript");
          noscript.innerHTML = `< img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />`;
          head.appendChild(noscript);

          let customdata = {
            content_ids: [],
            contents: [],
            content_name: "",
            content_category: "",
            currency: "USD",
            content_type: "",
          };
          let userdata = {
            em: "",
            ph: "",
            fn: "",
            ln: "",
            ct: "",
            st: "",
            zp: "",
            country: "",
            external_id: "",
            client_ip_address: "",
            client_user_agent: "",
            fbc: "",
            fbp: "",
            lead_id: "",
            anon_id: "",
            madid: "",
            page_id: "",
            page_scoped_user_id: "",
            ctwa_clid: "",
            ig_account_id: "",
            ig_sid: "",
          };
          let event_id = Math.round(Math.random() * 10000000000);
          FackbookAPI.Facebook(event_id, "PageView", customdata, userdata).then(
            (res) => {
              console.log(res);
            }
          );
        }

        if (data) {
          window.globalRouter = data;
          document.querySelector("link").href = data.icon;
          document.querySelector("#shop_title").innerHTML = data.shopName;
        }
        this.toggleLoading();
      })
      .catch(this.toggleLoading);
    // 获取浏览器当前路径
    let location = window.location.href;
    let result = location.substring(location.indexOf("?"));
    let arr = queryString.parse(result);
    let ad = arr.ad || "test";
    localStorage.setItem("ad", JSON.stringify(ad));
  }

  // 根据rem修改视口高度
  setRem() {
    let w = window.innerWidth;
    let h = window.innerHeight;
    let fontSize = 16;
    if (w >= 1400) {
      fontSize = 15;
    } else if (w < 1400 && w > 1024) {
      fontSize = 13;
    } else if (w <= 1024) {
      fontSize = 12;
    } else {
      fontSize = (w / 960) * 8;
    }
    document.documentElement.style.fontSize = fontSize + "px";
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  render() {
    const { showLoading } = this.state;
    if (showLoading) return <Loading />;
    return (
      <BrowserRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route path="/Success" component={Success} />
            <Route path="/Fail" component={Fail} />
            <Route
              path={window.globalRouter && `${window.globalRouter.routeShip}`}
              component={ShippingPolicy}
            />
            <Route
              path={
                window.globalRouter && `${window.globalRouter.routePrivacy}`
              }
              component={PrivacyPolicy}
            />
            <Route
              path={window.globalRouter && `${window.globalRouter.routeAbout}`}
              component={AboutUs}
            />
            <Route
              path={
                window.globalRouter && `${window.globalRouter.routeOrderDetail}`
              }
              component={OrderDetail}
            />
            <Route
              path={window.globalRouter && `${window.globalRouter.routeOrder}`}
              component={Order}
            />
            <Route
              path={
                window.globalRouter && `${window.globalRouter.routeCheckout}`
              }
              component={Checkout}
            />
            <Route
              path={window.globalRouter && `${window.globalRouter.routeCart}`}
              component={Cart}
            />
            <Route
              path={
                window.globalRouter &&
                `${window.globalRouter.routeProductDetail}/:id`
              }
              render={(props) => (
                <ProductDetail key={props.match.params.id} {...props} />
              )}
            />
            <Route
              path={
                window.globalRouter &&
                `${window.globalRouter.routeCategory}/:id`
              }
              render={(props) => (
                <Category key={props.match.params.id} {...props} />
              )}
            />
            <Route path="/" component={Home} />
            {/* <Redirect from="/" to={window.globalRouter.homePage} /> */}
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
