import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const customStatus = (payment_intent_id, status, c_site_url) => {
  return api.post(`${ApiEndpointConstants.CUSTOMSTATUS}`, {
    payment_intent_id: payment_intent_id,
    status: status,
    c_site_url: c_site_url,
  });
};
