import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import Loading from "./common/Loading";
import "../styles/aboutUs.scss";
import * as ConfigAPI from "../apis/ConfigAPI";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      about: "",
    };
  }

  componentDidMount() {
    this.toggleShow();
    ConfigAPI.getConfig("about").then((res) => {
      this.toggleShow();
      var data = res.data.data;
      if (data) {
        console.log(data);
        this.setState({ about: data.about });
      }
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  render() {
    const { about } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="about-page">
          <div className="head">
            <div className="title">About Us</div>
          </div>
          <div className="about-body">
            <p dangerouslySetInnerHTML={{ __html: about }}></p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default AboutUs;
