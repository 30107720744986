import React, { Component } from "react";
import "../../styles/common/footerBar.scss";
import * as ConfigAPI from "../../apis/ConfigAPI";
import Loading from "./Loading";
import payments from "../../image/payments.png";
import Tiwtter from "../../icons/Tiwtter";
import Fackbook from "../../icons/Fackbook";
import Instagram from "../../icons/Instagram";
import Linkedin from "../../icons/Linkedin";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
      description: "",
      email: "",
      address: "",
      phone: "",
      loadingShow: false,
    };
    this.routePage = this.routePage.bind(this);
  }

  componentDidMount() {
    this.toggleShow();
    ConfigAPI.getConfig("logo,shopDescription,email,address,phone").then(
      (res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          this.setState({
            email: data.email,
            phone: data.phone,
            address: data.address,
            logo: data.logo,
            description: data.shopDescription,
          });
        }
      }
    );
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  routePage(type) {
    let { history, location } = this.props;
    const search = location.search;
    switch (type) {
      case "home":
        history &&
          history.push({
            pathname: "/",
            search: location.search,
          });
        break;
      case "ship":
        history &&
          history.push({
            pathname: window.globalRouter.routeShip,
            search: location.search,
          });
        break;
      case "about":
        history &&
          history.push({
            pathname: window.globalRouter.routeAbout,
            search: location.search,
          });
        break;
      case "privacy":
        history &&
          history.push({
            pathname: window.globalRouter.routePrivacy,
            search: location.search,
          });
        break;
      default:
        break;
    }
  }

  render() {
    const { address, email, phone, description, logo } = this.state;
    return (
      <React.Fragment>
        <footer>
          <div className="content">
            <ul>
              <li>
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
              </li>
              <li>{description}</li>
            </ul>
            <ul>
              <h1>CONTACT US</h1>
              <li>Email : {email}</li>
              <li>Phone : {phone}</li>
              <li>Address: {address}</li>
              <li>100% Secure Payments </li>
              <li>
                <div className="img">
                  <img src={payments} alt="" />
                </div>
              </li>
            </ul>
            <ul>
              <h1>POLICIES AND TERMS</h1>
              <li onClick={() => this.routePage("about")}>About us</li>
              <li onClick={() => this.routePage("privacy")}>Privacy Policy</li>
              <li onClick={() => this.routePage("ship")}>Shipping Policy</li>
              <li>
                <span>
                  <Tiwtter size={10} color="#000" />
                </span>
                <span>
                  <Fackbook size={10} color="#000" />
                </span>
                <span>
                  <Linkedin size={10} color="#000" />
                </span>
                <span>
                  <Instagram size={10} color="#000" />
                </span>
              </li>
            </ul>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default FooterBar;
