import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getProductList = (page, per_page, keywords, category_id,tag_id) => {
  return api.post(`${ApiEndpointConstants.PRODUCT}`, {
    page,
    per_page,
    keywords,
    category_id,
    tag_id,
  });
};
