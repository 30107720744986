import React, { Component } from "react";
import reload from "../../image/reload.png";
import "../../styles/common/reload.scss";
class Reload extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { reloadingShow } = this.props;
    return (
      <div className={`reload-mask ${reloadingShow ? "show" : ""}`}>
        <div className="reload">
          <div className="box">
            <img src={reload} alt="" className="reload-img" />
            <p>Linking payment gateway partners...</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Reload;
