import React, { Component } from "react";
import spinner from "../../image/spinner.gif";
import "../../styles/common/loading.scss";
class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loadingShow } = this.props;
    return (
      <div className={`loading-mask ${loadingShow ? "show" : ""}`}>
        <div className="loading">
          <img src={spinner} alt="" />
        </div>
      </div>
    );
  }
}

export default Loading;
