export const BASE_URL =
  window.location.host.indexOf("localhost") < 0
    ? window.location.protocol + "//" + window.location.host
    : "https://test.sweedat.me";

// export const BASE_URL = "https://www.nicerefurbish.store";

export default {
  CATEGORY_LIST: "/api/product/categoryList",
  CONFIG: "/api/product/getDeploy",
  CATEGORY_DETAIL: "/api/product/getCategory",
  PRODUCT: "/api/product/productList",
  LOGIN: "/api/user/login",
  REGISTER: "/api/user/register",
  LOGOUT: "/api/user/logout",
  PRODUCT_DETAIL: "/api/product/getProduct",
  PAY: "/api/product/pay",
  ORDER_LIST: "/api/product/getOrderlist",
  ORDER_DETAIL: "/api/product/getOrder",
  GET_IP: "/index.php/api/tool/getcountry",
  TAG_LIST: "/api/product/labelList",
  Tag_DETAIL: "/api/product/getLabel",
  PIXEL: "/api/index/getpixel",
  FACEBOOK: "/api/facebook/EventsAll",
  CUSTOMSTATUS: "/api/product/customstatus",
};
