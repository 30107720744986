import React from "react";
const LogoSvg = ({
  viewBox = " 0 0 131 32",
  color = "#fff",
  w = 131,
  h = 32,
  ...props
}) => (
  <svg
    version="1.1"
    id="Livello_2"
    viewBox="0 0 131 32"
    width={w}
    height={h}
    {...props}
  >
    <g>
      <path
        d="M60.9,14.3v1.6c1.2,0,2.3,0,3.5,0.2c1,0.1,2,0.3,2.8,0.9c0.8,0.5,1.2,1.4,1.2,2.3V27c0,0.2-0.1,0.4-0.2,0.5
                        c-1.7,1.7-3.5,2.5-5.2,2.5c-4.2,0-7.2-4.6-7.7-12.2c0-0.5-0.1-2.2,0-3.2c0.5-8.2,3.8-12.8,8-12.8c3.5,0,6.5,2.6,8.6,9.5h1l-0.1-7.6
                        C71.4,1.8,67.7,0,63.2,0c-4.1,0-7.8,1.9-10.3,5.1c-3.3,4.4-4.2,10.5-2.8,15.8c0.9,3.5,2.8,6.7,5.7,8.9c1.9,1.4,4.3,2.2,6.7,2.2
                        c1.7,0,3.7-0.6,5.3-1.7c1-0.7,2.3-0.7,3.3-0.1l1.9,1.1h0.9V19.2c0-2.3,0.8-3,3.5-3.3v-1.6H60.9z"
      ></path>{" "}
      <path
        d="M24.6,20.2c-3.1,4.2-4.9,6.6-6.4,8c-1.2,1.1-2.2,1.3-4.2,1.3H7.7c-0.1,0-0.2-0.1-0.1-0.2L23.8,2.7l-0.8-2H3.2L0,11.1
                        l0.9,0.4c3-4.1,4.8-6.4,6.1-7.7C8.1,2.7,9.2,2.6,11,2.6h6c0.1,0,0.2,0.1,0.1,0.2L1,29.3l0.8,2h20.4l3.3-10.7L24.6,20.2z"
      ></path>{" "}
      <path
        d="M38.7,16.1c-1-0.3-3.9-0.2-3.9-0.2c-0.2,0-0.3,0.1-0.3,0.3v13c0,0.2,0.1,0.3,0.3,0.3h3.8c1.6,0,2.5-0.1,3.5-1
                        c1.2-1.1,2.7-3.1,5.3-6.6l0.9,0.4l-2.8,9.1H26.3v-1.4c0,0,0.6,0,1-0.1c0.5-0.1,1-0.3,1.3-0.8c0.3-0.4,0.4-0.9,0.4-1.3V4.3
                        c0-0.5-0.1-0.9-0.4-1.3c-0.3-0.4-0.8-0.7-1.3-0.8c-0.4-0.1-1-0.1-1-0.1V0.7h19.1l2.8,9.1l-0.9,0.4c-2.5-3.5-4.1-5.5-5.3-6.6
                        c-1-0.9-1.9-1-3.5-1h-3.8c-0.2,0-0.3,0.1-0.3,0.3V14c0,0.2,0.1,0.3,0.3,0.3c0,0,2.3,0,3.2-0.1c1.1-0.2,1.6-0.8,1.7-2
                        c0-0.8-0.4-2.5-0.4-2.7h1.4L44,20.2h-1.5C41.6,18.1,41,16.8,38.7,16.1z"
      ></path>{" "}
      <path
        d="M80.3,4.2c-1.4-1.7-2.2-2.1-4.2-2.1V0.7h9l13.7,23.5c0.1,0.1,0.2,0.1,0.2-0.1V6c0-0.8-0.2-1.7-0.7-2.4
                        c-0.6-0.8-1.5-1.2-2.5-1.4c0,0-0.6-0.1-1.8-0.1V0.7H105v1.4c-1.3,0.1-2.6,0.4-3.4,1.5c-0.5,0.7-0.7,1.5-0.7,2.4v25.3h-4.5L82.6,7.8
                        c-0.1-0.1-0.2-0.1-0.2,0.1V26c0,0.8,0.2,1.7,0.7,2.4c1,1.4,2.9,1.5,4.5,1.5v1.4H76.4v-1.4c1.3-0.1,2.6-0.4,3.4-1.5
                        c0.5-0.7,0.7-1.5,0.7-2.4V4.8C80.5,4.6,80.4,4.4,80.3,4.2z"
      ></path>{" "}
      <path
        d="M118.8,21.4h-8.6c-0.1,0-0.2-0.1-0.1-0.2l4.4-13.3c0-0.1,0.2-0.1,0.2,0l4.3,13.3C119,21.3,118.9,21.4,118.8,21.4z
                        M126.6,26.5l-8.5-25.8h-8v1.4c1.6,0,1.8,0.1,2.1,0.2c0.5,0.3,1,0.9,1.3,1.9c0,0,0.1,0.2,0,0.5l-7.3,22.1c-0.7,2.1-1.6,2.8-3.3,3
                        v1.4h9.3v-1.4c-2.7,0-3.6-0.6-3.6-0.6c-1.1-0.6-0.5-2.4-0.5-2.4l1.1-3.5c0.1-0.2,0.3-0.4,0.5-0.4h9.4c0.2,0,0.4,0.1,0.5,0.4
                        l1.1,3.5c0.1,0.5,0.3,1.1,0.2,1.6c-0.2,0.9-1.2,1.1-2,1.2c-0.6,0.1-1.1,0.1-1.7,0.1v1.4H131v-1.4C128.5,29.6,127.4,28.7,126.6,26.5
                        z"
      ></path>
    </g>
  </svg>
);

export default LogoSvg;
