import React, { Component } from "react";
import "../styles/productDetail.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import Loading from "./common/Loading";
import NoneContent from "../icons/NoneContent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Thumbs } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import "swiper/css/thumbs";
import * as ProductDetailAPI from "../apis/ProductDetailAPI";
import SwiperCore from "swiper/core";
import Number from "./common/Number";
import * as GetTagListAPI from "../apis/GetTagListAPI";
import * as ProductAPI from "../apis/ProductAPI";
import Person from "../icons/Person";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import * as FackbookAPI from "../apis/FacebookAPI";

SwiperCore.use([Navigation, Thumbs]);

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      product: {},
      sku: [],
      productList: [],
      cartArr: [],
      originalPrice: 0,
      currentPrice: 0,
      thumbsSwiper: null,
      tag: [],
    };
    this.changeSkuValue = this.changeSkuValue.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
    this.AddToCart = this.AddToCart.bind(this);
    this.routeCheckout = this.routeCheckout.bind(this);
    this.ShopNow = this.ShopNow.bind(this);
  }

  headerRef = React.createRef();
  numberRef = React.createRef();

  componentDidUpdate(prevProps) {
    // 检查URL参数是否变化
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // 执行当URL参数变化时需要进行的操作
      this.fetchData(this.props.match.params.id);
    }
  }

  componentDidMount() {
    //自动回顶
    window.scrollTo(0, 0);
    const { location, history } = this.props;
    let id;
    if (window.globalRouter) {
      id = location.pathname.replace(
        `${window.globalRouter.routeProductDetail}/`,
        ""
      );
      ProductDetailAPI.getProductDetail(id).then((res) => {
        var data = res.data.data;
        if (data != []) {
          console.log(data);
          let image = data.image && JSON.parse(data.image);
          let sku = data.skulist && JSON.parse(data.skulist);
          sku &&
            sku.map((item) => {
              item.selectValue = item.data[0];
            });
          data.commentList_yes.map((item, index) => {
            item.createtime = new Date(
              parseInt(item.createtime) * 1000
            ).toLocaleString();
            console.log(item.createtime);
          });
          let product = {
            id: data.id,
            image: image,
            title: data.title,
            originalPrice: data.originalPrice,
            currentPrice: data.currentPrice,
            description: data.description,
            commentList_yes: data.commentList_yes,
            star: Math.round(Math.random() * (5 - 4)) + 4,
            categorylist: data.categorylist,
            inventory: data.inventory,
          };
          this.setState({ product, sku }, () => {
            let contents = [];
            sku.map((item) => {
              item.data.map((sku) => {
                contents.push(sku.skuid);
              });
            });
            // fbq
            if (window.fbq) {
              window.fbq("track", "ViewContent", {
                content_ids: [product.id],
                contents: [{ id: product.id, quantity: "1" }],
                content_name: product.title,
                content_category: product.categorylist.title,
                value: product.currentPrice,
                currency: "USD",
                content_type: "product",
              });
              let customdata = {
                content_ids: [product.id],
                contents: [{ id: product.id, quantity: "1" }],
                content_name: product.title,
                content_category: product.categorylist.title,
                currency: "USD",
                value: product.currentPrice,
                content_type: "product",
              };
              let userdata = {
                em: "",
                ph: "",
                fn: "",
                ln: "",
                ct: "",
                st: "",
                zp: "",
                country: "",
                external_id: "",
                client_ip_address: "",
                client_user_agent: "",
                fbc: "",
                fbp: "",
                lead_id: "",
                anon_id: "",
                madid: "",
                page_id: "",
                page_scoped_user_id: "",
                ctwa_clid: "",
                ig_account_id: "",
                ig_sid: "",
              };
              let event_id = Math.round(Math.random() * 10000000000);
              FackbookAPI.Facebook(
                event_id,
                "AddToCart",
                customdata,
                userdata
              ).then((res) => {
                console.log(res);
              });
            }
          });
        }
      });
      //
    }

    // get related product
    GetTagListAPI.getTagList(1, 8, 0).then((res) => {
      console.log(res);
      var data = res.data.data.list;
      let { tag } = this.state;
      if (data) {
        data &&
          data.map((item) => {
            let obj = {
              id: item.id,
              title: item.title,
              data: [],
            };
            tag.push(obj);
          });
        this.setState({ tag }, () => {
          tag.map((item, index) => {
            ProductAPI.getProductList(1, 20, "", "", item.id).then((res) => {
              var data = res.data.data.list;
              if (data) {
                data.map((item) => {
                  item.image = JSON.parse(item.image);
                  item.star = Math.round(Math.random() * (5 - 4)) + 4;
                  item.discount = Math.ceil(
                    ((parseInt(item.originalPrice) -
                      parseInt(item.currentPrice)) /
                      parseInt(item.originalPrice)) *
                      100
                  );
                });
                tag[index].data = data;
                this.setState({ tag });
              }
            });
          });
        });
      }
    });
  }

  routeProductDetail(id) {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: `${window.globalRouter.routeProductDetail}/${id}`,
        search: location.search,
      });
  }

  routeCheckout() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: window.globalRouter.routeCheckout,
        search: location.search,
      });
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  // sku change
  changeSkuValue(index, skuIndex) {
    let { sku, product } = this.state;
    sku[index].selectValue = sku[index].data[skuIndex];
    this.setState({ sku }, () => {
      let originalPrice = 0;
      let currentPrice = 0;
      sku.map((item, index) => {
        originalPrice += parseFloat(item.selectValue.originalPrice);
        currentPrice += parseFloat(item.selectValue.currentPrice);
      });
      product.originalPrice = originalPrice.toFixed(2);
      product.currentPrice = currentPrice.toFixed(2);
      this.setState({
        product,
      });

      // fbq
      if (window.fbq) {
        let content_ids = [];
        sku.map((item) => {
          content_ids.push(item.selectValue.skuid);
        });

        window.fbq("track", "CustomizeProduct", {
          content_ids: content_ids,
        });
        let customdata = {
          content_ids: content_ids,
          contents: [],
          content_name: "",
          content_category: "",
        };
        let userdata = {
          em: "",
          ph: "",
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        let event_id = Math.round(Math.random() * 10000000000);
        FackbookAPI.Facebook(
          event_id,
          "CustomizeProduct",
          customdata,
          userdata
        ).then((res) => {
          console.log(res);
        });
      }
    });
  }

  setThumbsSwiper = (swiper) => {
    this.setState({ thumbsSwiper: swiper });
  };

  AddToCart() {
    console.log("add to cart --------");
    const { product, sku } = this.state;
    let cartArr = JSON.parse(localStorage.getItem("cartArr")) || [];
    let param;
    if (this.numberRef) {
      console.log(this.headerRef.current);
      let num = this.numberRef.current.state.num;
      param = {
        id: product.id,
        image: product.image,
        title: product.title,
        sku: sku,
        price: product.currentPrice,
        num: num,
        total: (num * +product.currentPrice).toFixed(2),
        categoryList: product.categorylist,
      };

      const matchCartArr = () => {
        if (cartArr.length <= 0) {
          cartArr.push(param);
          return;
        }
        let sameProduct = cartArr.filter((item) => item.id === product.id);
        if (sameProduct.length === 0) {
          cartArr.push(param);
          return;
        }

        //id
        let sameId = false;
        for (let i = 0; i < cartArr.length; i++) {
          if (cartArr[i].id !== param.id) {
            continue;
          } else {
            let sameSku = true;
            console.log(param.sku);
            for (let j = 0; j < param.sku.length; j++) {
              if (
                param.sku[j].selectValue.skuid !==
                cartArr[i].sku[j].selectValue.skuid
              ) {
                sameSku = false;
                continue;
              }
            }
            if (sameSku) {
              sameId = true;
              cartArr[i].num += param.num;
              cartArr[i].total = (
                parseFloat(cartArr[i].total) + parseFloat(param.total)
              ).toFixed(2);
            }
          }
        }

        if (!sameId) {
          cartArr.push(param);
          return;
        }
      };
      matchCartArr();
      localStorage.setItem("cartArr", JSON.stringify(cartArr));
      this.headerRef.current.showCart();
      this.headerRef.current.renderCart();

      if (window.fbq) {
        window.fbq("track", "AddToCart", {
          content_ids: [param.id],
          contents: [{ id: param.id, quantity: param.num }],
          content_name: param.title,
          content_category: param.categoryList.title,
          value: param.total,
          currency: "USD",
          content_type: "product",
        });
        let customdata = {
          content_ids: [param.id],
          contents: [{ id: param.id, quantity: param.num }],
          content_name: param.title,
          content_category: param.categoryList.title,
          currency: "USD",
          value: param.total,
          content_type: "product",
        };
        let userdata = {
          em: "",
          ph: "",
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        let event_id = Math.round(Math.random() * 10000000000);
        FackbookAPI.Facebook(event_id, "AddToCart", customdata, userdata).then(
          (res) => {
            console.log(res);
          }
        );
      }
    }
  }

  // shop now
  ShopNow() {
    const { product, sku } = this.state;
    if (this.numberRef) {
      console.log(this.headerRef.current);
      let num = this.numberRef.current.state.num;
      let param;
      param = {
        id: product.id,
        image: product.image,
        title: product.title,
        sku: sku,
        price: product.currentPrice,
        num: num,
        total: (num * +product.currentPrice).toFixed(2),
        categoryList: product.categorylist,
      };
      let shopNow = [param];
      localStorage.setItem("shopNow", JSON.stringify(shopNow));
      let { history, location } = this.props;
      const search = location.search;
      // history.push(`${window.globalRouter.routeCheckout}?formToDetail`);
      history &&
        history.push({
          pathname: `${window.globalRouter.routeCheckout}/1`,
          // search: location.search,
          search: search,
        });
      // 立即购买
      if (window.fbq) {
        window.fbq("track", "AddToCart", {
          content_ids: [param.id],
          contents: [{ id: param.id, quantity: param.num }],
          content_name: param.title,
          content_category: param.categoryList.title,
          value: param.total,
          currency: "USD",
          content_type: "product",
        });
        let customdata = {
          content_ids: [param.id],
          contents: [{ id: param.id, quantity: param.num }],
          content_name: param.title,
          content_category: param.categoryList.title,
          value: param.total,
          currency: "USD",
          content_type: "product",
        };
        let userdata = {
          em: "",
          ph: "",
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        let event_id = Math.round(Math.random() * 10000000000);
        FackbookAPI.Facebook(event_id, "AddToCart", customdata, userdata).then(
          (res) => {
            console.log(res);
          }
        );
      }
    }
  }

  render() {
    const { product, sku, tag } = this.state;

    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="productDetail-content">
          <div className="img-row">
            <div className="left">
              <div className="image-box">
                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={100}
                  slidesPerView={1}
                  // autoplay={{ delay: 3000 }}
                  // navigation
                  // pagination={{ clickable: true }}
                  thumbs={{ swiper: this.state.thumbsSwiper }}
                >
                  {/* 1 */}
                  {product.image &&
                    product.image.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="img">
                            <img src={BASE_URL + item} alt="" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
              {/* 缩略图 */}
              <div className="swiper-row">
                <Swiper
                  onSwiper={this.setThumbsSwiper}
                  loop={true}
                  spaceBetween={20}
                  slidesPerView={5}
                  freeMode={true}
                  watchSlidesVisibility={true}
                  watchSlidesProgress={true}
                  className="myThumbsSwiper"
                >
                  {/* 1 */}
                  {product.image &&
                    product.image.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <img src={BASE_URL + item} alt="" />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>

            <div className="info-box">
              <div className="title">{product.title}</div>
              <div className="star">
                <Rating name="read-only" value={product.star || ""} readOnly />
                <div className="inventory">
                  Inventory (<span>{product.inventory}</span>)
                </div>
              </div>
              <div className="price">
                <div className="original">${product.originalPrice}</div>
                <div className="current">${product.currentPrice}</div>
              </div>

              <div className="sku-row">
                {sku.map((item, index) => {
                  return (
                    <div className="col" key={index}>
                      <div className="title">
                        {item.title}:<span>{item.selectValue.content}</span>
                      </div>
                      <div className="select">
                        {item.data.map((sku, skuIndex) => {
                          return (
                            <div
                              className={`li ${
                                item.selectValue.content === sku.content
                                  ? "active"
                                  : ""
                              }`}
                              key={skuIndex}
                              onClick={() => {
                                this.changeSkuValue(index, skuIndex);
                              }}
                            >
                              {sku.content}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="cart-row">
                <Number
                  {...this.props}
                  ref={this.numberRef}
                  maxNum={product.inventory}
                />
                {/* <div className="addToCart" onClick={this.AddToCart}>
                  ADD TO CART
                </div> */}
              </div>
              <div className="btn-row">
                <div className="addToCart" onClick={this.AddToCart}>
                  ADD TO CART
                </div>
                <div className="buy-now" onClick={this.ShopNow}>
                  PROCEED CHECKOUT
                </div>
              </div>
            </div>
          </div>
          <div className="description-row">
            <div className="title">DESCRIPTION</div>
            <div className="txt">
              <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
            </div>
          </div>
          {product.commentList_yes && product.commentList_yes.length > 0 ? (
            <div className="comment-row">
              <div className="title">CUSTOMER REVIEWS</div>
              <ul className="comment-ul">
                {product.commentList_yes &&
                  product.commentList_yes.map((comment, index) => {
                    return (
                      <li className="comment-li" key={index}>
                        <div className="head">
                          <Person size={30} />{" "}
                          <div className="email">{comment.email}</div>
                        </div>
                        <div className="star">
                          <Rating
                            name="read-only"
                            value={comment.star}
                            readOnly
                          />
                        </div>
                        <p
                          dangerouslySetInnerHTML={{ __html: comment.content }}
                          className="content"
                        ></p>
                        {/* <div className="time">{comment.createtime}</div> */}
                      </li>
                    );
                  })}
              </ul>
            </div>
          ) : (
            ""
          )}
          {/* 推荐 */}
          {tag.map((item, index) => {
            return (
              <div className="related-row" key={index}>
                <div className="title">{item.title}</div>
                <div className="product-row">
                  {item.data &&
                    item.data.map((tagLi, TagIndex) => {
                      return (
                        <div
                          className="product-li"
                          key={TagIndex}
                          onClick={() => this.routeProductDetail(tagLi.id)}
                        >
                          <div className="img-box">
                            <img src={BASE_URL + tagLi.image[0]} alt="" />
                            <div className="discount-box">
                              {tagLi.discount}% OFF
                            </div>
                          </div>
                          <div className="txt-box">{tagLi.title}</div>
                          <div className="star">
                            <Rating
                              name="read-only"
                              value={tagLi.star || ""}
                              readOnly
                            />
                          </div>
                          <div className="price">
                            <span>${tagLi.originalPrice}</span>$
                            {tagLi.currentPrice}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ProductDetail;
