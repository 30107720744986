import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getTagList = (page, per_page, is_home) => {
  return api.post(`${ApiEndpointConstants.TAG_LIST}`, {
    page,
    per_page,
    is_home,
  });
};
