import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../styles/order.scss";
import * as GetOrderListAPI from "../apis/GetOrderListAPI";
import SearchIcon from "../icons/SearchIcon";
import NoOrder from "../icons/NoOrder";
import Loading from "./common/Loading";
import Pagination from "@material-ui/lab/Pagination";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: [],
      page: 1,
      per_page: 8,
      keywords: "",
      count: 1,
      loadingShow: false,
    };
    this.routeOrderDetail = this.routeOrderDetail.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    let { page, per_page } = this.state;

    if (localStorage.getItem("email") !== "") {
      let keywords = JSON.parse(localStorage.getItem("email"));
      this.setState({ keywords }, () => {
        this.GetOrderList(page, per_page, keywords);
      });
    }
  }

  GetOrderList(page, per_page, keywords) {
    this.toggleLoading();
    GetOrderListAPI.GetOrderList(page, per_page, keywords).then((res) => {
      this.toggleLoading();
      var data = res.data.data;
      var total = res.data.data.total;
      let count = Math.ceil(total / per_page);
      if (data) {
        data.list.map((item) => {
          item.createtime = new Date(item.createtime * 1000).toLocaleString();
        });
        this.setState({ orderList: data.list, count });
      }
    });
  }
  handlePageChange(e, page) {
    this.setState({ page: page }, () => {
      const { page, per_page, keywords } = this.state;
      this.GetOrderList(page, per_page, keywords);
    });
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  routeOrderDetail(id) {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: `${window.globalRouter.routeOrderDetail}/${id}`,
        search: location.search,
      });
  }
  inputChange(e) {
    this.setState({ keywords: e.target.value });
  }

  search() {
    const { page, per_page, keywords } = this.state;
    this.GetOrderList(page, per_page, keywords);
  }

  render() {
    const { orderList, page, count, keywords } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="order-page">
          <div className="head">
            <div className="title">Order</div>
          </div>
          <div className="order-body">
            <div className="search">
              <input
                type="text"
                value={keywords || ""}
                placeholder="please enter your email ..."
                onChange={this.inputChange}
              />
              <SearchIcon size={25} color="#bebebe" onClick={this.search} />
            </div>
            {orderList.length > 0 ? (
              <div className="order-list">
                {orderList.map((item, index) => {
                  return (
                    <div className="order-li" key={index}>
                      <div className="order">
                        <div className="title">Order Number</div>
                        <div className="value">#{item.orderNu}</div>
                      </div>
                      <div className="order">
                        <div className="title">Price</div>
                        <div className="value">${item.accout}</div>
                      </div>
                      <div className="order">
                        <div className="title">Status</div>
                        <div className={item.state === 0 ? "cancel" : "pay"}>
                          {item.state === 0 ? "cancel" : "Payed"}
                        </div>
                      </div>
                      <div className="order">
                        <div className="title">Create time</div>
                        <div className="value">{item.createtime}</div>
                      </div>
                      <div className="btn">
                        <div
                          className="view"
                          onClick={() => this.routeOrderDetail(item.id)}
                        >
                          VIEW
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="order-footer">
                  <Pagination
                    page={page}
                    count={count}
                    onChange={this.handlePageChange}
                    variant="outlined"
                    color="standard"
                  />
                </div>
              </div>
            ) : (
              <div className="no-order">
                <NoOrder size={150} color="#bebebe" />
                <p className="txt">You have no order.</p>
              </div>
            )}
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Order;
