import React, { Component } from "react";
import "../../styles/common/headerBar.scss";
import LogoSvg from "../../icons/LogoSvg";
import SearchIcon from "../../icons/SearchIcon";
import PersonalIcon from "../../icons/PersonalIcon";
import ShippingBagIcon from "../../icons/ShippingBagIcon";
import CloseIcon from "../../icons/CloseIcon";
import EyeOn from "../../icons/EyeOn";
import EyeOff from "../../icons/EyeOff";
import Gou from "../../icons/Gou";
import MenuIcon from "../../icons/MenuIcon";
import * as GetCategoryListAPI from "../../apis/GetCategoryListAPI";
import { BASE_URL } from "../../constants/ApiEndpointConstants";
import NoneContent from "../../icons/NoneContent";
import * as ConfigAPI from "../../apis/ConfigAPI";
import SafeIcon from "../../icons/SafeIcon";
import trustpoilt from "../../image/trustpoilt.png";
import creditcard from "../../image/creditcard.jpg";
import ServiceIcon from "../../icons/ServiceIcon";
import profileImg from "../../image/profileImg.jpg";
import hello from "../../image/hello.png";
import AllowDown from "../../icons/AllowDown";
import EmailIcon from "../../icons/EmailIcon";
import ellipsis from "../../image/ellipsis.gif";
import * as FackbookAPI from "../../apis/FacebookAPI";
import queryString from "query-string";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabFlag: false,
      passwordFlag: false,
      showSign: false,
      showCart: false,
      showMenu: false,
      categoryList: [],
      cartArr: [],
      cartNum: 0,
      total: 0,
      isToken: false,
      logo: "",
      loadingShow: false,
      showSafe: false,
      message: [],
      showService: false,
    };
    this.changeTabFlag = this.changeTabFlag.bind(this);
    this.changePasswordFlag = this.changePasswordFlag.bind(this);
    this.showSignBox = this.showSignBox.bind(this);
    this.showCart = this.showCart.bind(this);
    this.showMenu = this.showMenu.bind(this);

    this.routeCategory = this.routeCategory.bind(this);
    this.routeHome = this.routeHome.bind(this);
    this.removeProductFromCart = this.removeProductFromCart.bind(this);
    this.routeCart = this.routeCart.bind(this);
    this.routeCheckout = this.routeCheckout.bind(this);
    this.routePage = this.routePage.bind(this);
    this.showSafe = this.showSafe.bind(this);
    this.showService = this.showService.bind(this);
    this.createMessageLi = this.createMessageLi.bind(this);
    this.changeContent = this.changeContent.bind(this);
  }

  componentDidMount() {
    this.renderCart();
    GetCategoryListAPI.getCategoryList(1, 10).then((res) => {
      var list = res.data.data.list;
      if (list) {
        this.setState({ categoryList: list });
      }
    });
    ConfigAPI.getConfig("logo").then((res) => {
      var data = res.data.data;
      if (data) {
        this.setState({
          logo: data.logo,
        });
      }
    });
  }

  changeTabFlag() {
    this.setState({ tabFlag: !this.state.tabFlag });
  }
  changePasswordFlag() {
    this.setState({ passwordFlag: !this.state.passwordFlag });
  }
  showSignBox() {
    this.setState({ showSign: !this.state.showSign });
  }
  showCart() {
    this.setState({ showCart: !this.state.showCart });
  }
  showMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  routeCategory(id) {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: `${window.globalRouter.routeCategory}/${id}`,
        search: location.search,
      });
    this.setState({ showMenu: false });
  }
  routeHome() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/",
        search: location.search,
      });
    this.setState({ showCart: false, showMenu: false });
  }

  routeCart() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: window.globalRouter.routeCart,
        search: location.search,
      });
    this.setState({ showCart: false, showMenu: false });
  }

  routeCheckout() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: window.globalRouter.routeCheckout,
        search: location.search,
      });
    this.setState({ showCart: false });
  }

  routePage(type) {
    let { history, location } = this.props;
    const search = location.search;
    switch (type) {
      case "home":
        history &&
          history.push({
            pathname: "/",
            search: location.search,
          });
        this.setState({ showMenu: false });
        break;
      case "ship":
        history &&
          history.push({
            pathname: window.globalRouter.routeShip,
            search: location.search,
          });
        this.setState({ showMenu: false });
        break;
      case "about":
        history &&
          history.push({
            pathname: window.globalRouter.routeAbout,
            search: location.search,
          });
        this.setState({ showMenu: false });
        break;
      case "privacy":
        history &&
          history.push({
            pathname: window.globalRouter.routePrivacy,
            search: location.search,
          });
        this.setState({ showMenu: false });
        break;
      case "order":
        history &&
          history.push({
            pathname: window.globalRouter.routeOrder,
            search: location.search,
          });
        this.setState({ showService: false ,showMenu: false });
        break;
      default:
        break;
    }
  }

  renderCart() {
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr")) || [];
      let num = 0;
      let total = 0;
      cartArr.map((item) => {
        num += item.num;
        total = parseFloat(parseFloat(total) + parseFloat(item.total)).toFixed(
          2
        );
      });

      this.setState({ cartArr, cartNum: num, total }, () => {
        localStorage.setItem("cartArr", JSON.stringify(cartArr));
      });
    }
  }

  removeProductFromCart(index) {
    let { cartArr } = this.state;
    cartArr.splice(index, 1);
    this.setState({ cartArr }, () => {
      localStorage.setItem("cartArr", JSON.stringify(cartArr));
      this.renderCart();
    });
  }

  showSafe() {
    this.setState({
      showSafe: !this.state.showSafe,
      showService: false,
    });
  }

  showService() {
    this.setState({
      showService: !this.state.showService,
      showSafe: false,
    });
  }

  createMessageLi() {
    let { message } = this.state;
    const obj = `<img src=${ellipsis}>`;
    message.push(obj);
    this.setState({ message }, () => {
      setTimeout(this.changeContent, 3000);
      if (window.fbq) {
        window.fbq("track", "Contact", {
          search_string: "support@uscustomer-services.online",
        });
        let customdata = {
          search_string: "support@uscustomer-services.online",
        };
        let userdata = {
          em: "",
          ph: "",
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        let event_id = Math.round(Math.random() * 10000000000);
        FackbookAPI.Facebook(event_id, "Contact", customdata, userdata).then(
          (res) => {
            console.log(res);
          }
        );
      }
    });
  }

  changeContent() {
    let { message } = this.state;
    message.splice(message.length - 1, 1);
    const obj = `<div>Our customer service email address is <a>support@uscustomer-services.online</a>, if you have any questions, please feel free to email us</div>`;
    message.push(obj);
    this.setState({ message });
  }

  render() {
    const {
      tabFlag,
      passwordFlag,
      showSign,
      showCart,
      showMenu,
      categoryList,
      cartArr,
      cartNum,
      total,
      logo,
      showSafe,
      message,
      showService,
    } = this.state;

    let { location } = this.props;
    let id;
    if (location.search !== "") {
      let arr = queryString.parse(location.search);
      id = arr.categoryId;
    }

    return (
      <React.Fragment>
        {/* service */}
        <div className="service">
          <div className="service-icon" onClick={this.showService}>
            <ServiceIcon size={30} color="#112d57" />
          </div>
          <div className={`service-box ${showService ? "service-show" : ""}`}>
            <div className="head">
              <div className="head-bar">
                <div className="left">
                  <div className="profile">
                    <img src={profileImg} alt="" />
                  </div>
                  <div className="text">
                    Hi there !{" "}
                    <div className="img">
                      <img src={hello} alt="" />
                    </div>
                  </div>
                </div>
                <AllowDown size={30} color="#fff" onClick={this.showService} />
              </div>
              <div className="bottom-bar">
                <div className="dian"></div>
                <div className="txt">We are offline</div>
              </div>
            </div>
            <div className="content">
              <div className="contact-box">
                <div className="message-head">
                  Hi! What brings you here today? We'll be happy to assist you!
                </div>
                <div
                  className="message-li"
                  onClick={() => this.routePage("order")}
                >
                  <SearchIcon size={14} color="#165ac0" /> Track the Order
                </div>
                <div className="message-li" onClick={this.createMessageLi}>
                  <EmailIcon size={24} color="#bfbfbf" />
                  Contact Support
                </div>
              </div>
              <div className="message-box">
                {message.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="li">
                        <div className="r">
                          <EmailIcon size={24} color="#bfbfbf" />
                          Contact Support
                        </div>
                      </div>
                      <div className="l2">
                        <div
                          className="l"
                          dangerouslySetInnerHTML={{ __html: item }}
                        ></div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/*safe  */}
        <div className="safe" onClick={this.showSafe}>
          <div className="safe-icon">
            <SafeIcon size={30} />
          </div>
          <div className={`safe-box ${showSafe ? "safe-show" : ""}`}>
            <div className="sharp"></div>
            <div className="head-img">
              <img src={trustpoilt} alt="" />
            </div>
            <div className="image">
              <img src={creditcard} alt="" />
            </div>
            <div className="txt">
              <SafeIcon size={20} />
              <div className="text">
                {" "}
                We are committed to protecting the security of your personal
                data. We will regularly review and update security measures to
                ensure data security.
              </div>
            </div>
          </div>
        </div>

        {/* menu */}
        <div className={`menu-mask ${showMenu ? "show-menu" : ""}`}>
          <div className="menu">
            <div className="head">
              MENU
              <div className="close">
                <CloseIcon size={25} onClick={this.showMenu} />
              </div>
            </div>
            <div className="content">
              <li
                className={location.pathname === "/" ? "active-li" : ""}
                onClick={() => this.routePage("home")}
              >
                Home
              </li>
              {categoryList.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={id == item.id ? "active-li" : ""}
                    onClick={() => this.routeCategory(item.id)}
                  >
                    {item.title}
                  </li>
                );
              })}
              <li
                className={
                  location.pathname === window.globalRouter.routeAbout
                    ? "active-li"
                    : ""
                }
                onClick={() => this.routePage("about")}
              >
                About Us
              </li>
              <li
                className={
                  location.pathname === window.globalRouter.routePrivacy
                    ? "active-li"
                    : ""
                }
                onClick={() => this.routePage("privacy")}
              >
                Privacy Policy
              </li>
              <li
                className={
                  location.pathname === window.globalRouter.routeShip
                    ? "active-li"
                    : ""
                }
                onClick={() => this.routePage("ship")}
              >
                Shipping Policy
              </li>
              <li
                className={
                  location.pathname === window.globalRouter.routeOrder
                    ? "active-li"
                    : ""
                }
                onClick={() => this.routePage("order")}
              >
                Track the Order
              </li>
            </div>
          </div>
        </div>
        {/* shopping cart */}
        <div className={`shoppingCart-mask ${showCart ? "show-cart" : ""}`}>
          <div className="shopping-cart">
            <div className="header">
              Cart
              <CloseIcon size={30} color="#000" onClick={this.showCart} />
            </div>
            {cartArr.length > 0 ? (
              <React.Fragment>
                <div className="content">
                  {cartArr.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <li>
                          <div className="left">
                            <div className="img">
                              <img src={BASE_URL + item.image[0]} alt="" />
                            </div>
                            <div className="info-box">
                              <div className="title">{item.title}</div>
                              <div className="sku">
                                {item.sku.map((skuItem, skuIndex) => {
                                  return (
                                    <React.Fragment key={skuIndex}>
                                      {skuIndex > 0 ? "," : ""}
                                      {skuItem.selectValue.content}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <div className="price">
                                {item.num} , <span>${item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className="close">
                            <CloseIcon
                              size={20}
                              onClick={() => this.removeProductFromCart(index)}
                            />
                          </div>
                        </li>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="footer">
                  <div className="sub-row">
                    <div className="title">Subtotal:</div>
                    <div className="price">${total}</div>
                  </div>
                  <div className="btn" onClick={this.routeCart}>
                    VIEW CART
                  </div>
                  <div className="btn" onClick={this.routeCheckout}>
                    PROCEED CHECKOUT
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="no-product">
                <NoneContent size={150} />
                <div className="txt">Your cart is currently empty.</div>
                <div className="btn" onClick={this.routeHome}>
                  RETURN TO SHOP
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="nav">
          FREE SHIPPING AND RETURNS. EXPLORE NEW ARRIVALS.
        </div>
        <header>
          <div className="menu">
            <MenuIcon size={25} onClick={this.showMenu} />
          </div>
          <div className="left">
            <div className="logo" onClick={this.routeHome}>
              <img src={logo} alt="" />
            </div>
            <ul className="page">
              {categoryList.map((item, index) => {
                return (
                  <li key={index} onClick={() => this.routeCategory(item.id)}>
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div>
          <ul className="tool">
            {/* <li>
              <SearchIcon size={18} color="#000" />
            </li> */}
            {/* <li>
              <PersonalIcon size={20} color="#000" onClick={()=>this.routePage('order')} />
            </li> */}
            <li>
              <ShippingBagIcon size={20} color="#000" onClick={this.showCart} />
              <div className="num">{cartNum}</div>
            </li>
          </ul>
        </header>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
