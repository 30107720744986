import React, { Component } from "react";
import "../../styles/common/number.scss";
import AddIcon from "../../icons/AddIcon";
import SubIcon from "../../icons/SubIcon";

class Number extends Component {
  constructor(props) {
    super(props);
    this.state = {
      num: props.num || 1,
      maxNum: props.maxNum,
    };
    this.sub = this.sub.bind(this);
    this.add = this.add.bind(this);
  }

  componentDidMount() {
    console.log(this.state.maxNum);
  }

  add() {
    let { num, maxNum } = this.state;
    num++;
    if (num > maxNum) {
      return;
    }
    this.setState({ num });
    const { receiveNum } = this.props;
    receiveNum && receiveNum(num);
  }

  sub() {
    let { num } = this.state;
    if (num === 1) return;
    num--;
    this.setState({ num });
    const { receiveNum } = this.props;
    receiveNum && receiveNum(num);
  }

  render() {
    const { num } = this.state;
    return (
      <React.Fragment>
        <div className="numberBar">
          <div className="sub" onClick={this.sub}>
            <SubIcon size={20} />
          </div>
          <div className="count">{num}</div>
          <div className="add" onClick={this.add}>
            <AddIcon size={20} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Number;
