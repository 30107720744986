import React from "react";

const Linkedin = ({
  color = "#8a8a8a",
  viewBox = "0 0 24 24",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="8724"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M236.8 334.4v656H16v-656h220.8z m12.8-204.8c0 32-9.6 59.2-35.2 81.6-22.4 22.4-52.8 32-91.2 32-38.4 0-65.6-9.6-88-32S0 164.8 0 129.6C0 97.6 12.8 70.4 35.2 48s52.8-32 88-32 65.6 9.6 88 32 38.4 51.2 38.4 81.6z m771.2 484.8V992H804.8V640c0-46.4-9.6-81.6-28.8-110.4-19.2-25.6-46.4-41.6-84.8-41.6-28.8 0-49.6 6.4-68.8 22.4-19.2 16-32 35.2-41.6 56-6.4 12.8-6.4 32-6.4 52.8v371.2H355.2V560 329.6h216v94.4c9.6-16 19.2-25.6 28.8-38.4 9.6-9.6 22.4-22.4 38.4-35.2 16-12.8 35.2-22.4 56-28.8 22.4-6.4 46.4-9.6 75.2-9.6 75.2 0 134.4 25.6 182.4 75.2 48 57.6 68.8 129.6 68.8 227.2z"
      fill={color}
      p-id="8725"
    ></path>
  </svg>
);

export default Linkedin;