import React from "react";

const ShippingBagIcon = ({
  viewBox = " 0 0 24 24",
  color = "#2c2c2c",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="172472"
    id="mx_n_1712143138285"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M924.699811 299.999862a102.399953 102.399953 0 0 0-102.399953-95.199956H716.799906a204.799906 204.799906 0 0 0-409.599812 0h-105.999951A102.399953 102.399953 0 0 0 98.80019 299.999862L59.400208 859.599605A153.59993 153.59993 0 0 0 213.000137 1023.99953h598.999725a153.59993 153.59993 0 0 0 153.59993-164.399925zM512 102.399953a102.399953 102.399953 0 0 1 102.399953 102.399953H409.600047A102.399953 102.399953 0 0 1 512 102.399953z m336.899845 802.799632a51.199977 51.199977 0 0 1-37.399982 16.399992h-598.999726a51.199977 51.199977 0 0 1-51.199976-54.799975l39.899982-559.599743h105.999951v102.399953a51.199977 51.199977 0 0 0 102.399953 0V307.199859h204.799906v102.399953a51.199977 51.199977 0 0 0 102.399953 0V307.199859h105.999951l39.899982 559.609743a51.199977 51.199977 0 0 1-13.799994 38.399983z"
      fill={color}
      p-id="172473"
    ></path>
  </svg>
);

export default ShippingBagIcon;
