import React from "react";
const SearchIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#444444",
  ...props
}) => (
  <svg
    viewBox="0 0 1025 1024"
    p-id="54167"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M992.256 871.40352l-242.54464-206.29504c-25.06752-22.56896-51.89632-32.93184-73.54368-31.92832 57.26208-67.072 91.83232-154.07104 91.83232-249.18016 0-212.0704-171.9296-384-384-384s-384 171.9296-384 384 171.9296 384 384 384c95.08864 0 182.10816-34.59072 249.18016-91.8528-1.00352 21.66784 9.35936 48.47616 31.92832 73.54368l206.29504 242.54464c35.328 39.23968 93.02016 42.55744 128.22528 7.35232s31.88736-92.89728-7.35232-128.22528zM384 640c-141.39392 0-256-114.60608-256-256s114.60608-256 256-256 256 114.60608 256 256-114.60608 256-256 256z"
      fill={color}
      p-id="54168"
    ></path>
  </svg>
);

export default SearchIcon;
