import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../styles/category.scss";
import * as GetCategoryDetailAPI from "../apis/GetCategoryDetailAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import Loading from "./common/Loading";
import * as ProductAPI from "../apis/ProductAPI";
import NoneContent from "../icons/NoneContent";
import Pagination from "@material-ui/lab/Pagination";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import LogoSvg from "../icons/LogoSvg";
import * as FackbookAPI from "../apis/FacebookAPI";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {},
      loadingShow: false,
      page: 1,
      per_page: 8,
      count: 1,
      keywords: "",
      productList: [],
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidUpdate(prevProps) {
    // 检查URL参数是否变化
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // 执行当URL参数变化时需要进行的操作
      this.fetchData(this.props.match.params.id);
    }
  }

  componentDidMount() {
    //自动回顶
    window.scrollTo(0, 0);
    const { location } = this.props;
    let id;
    if (window.globalRouter) {
      id = location.pathname.replace(
        `${window.globalRouter.routeCategory}/`,
        ""
      );
      // category
      this.toggleLoading();
      GetCategoryDetailAPI.getCategoryDetail(id).then((res) => {
        this.toggleLoading();
        var data = res.data.data;
        this.setState({ category: data });
      });
      this.getProductList(1, 8, "", id);
    }
  }

  getProductList(page, per_page, keywords, id) {
    this.toggleLoading();
    ProductAPI.getProductList(page, per_page, keywords, id).then((res) => {
      this.toggleLoading();
      var data = res.data.data;
      var total = data.total;
      let count = Math.ceil(total / 8);
      if (data) {
        data.list.map((item) => {
          item.image = JSON.parse(item.image);
          item.star = Math.round(Math.random() * (5 - 4)) + 4;
          item.discount = Math.ceil(
            ((parseInt(item.originalPrice) -
              parseInt(item.currentPrice)) /
              parseInt(item.originalPrice)) *
              100
          );
        });
        this.setState({ productList: data.list, count }, () => {
          // fbq
          if (window.fbq) {
            let contents = [];
            let content_ids = [];
            let content_name = [];
            const { category } = this.state;
            data.list.map((item) => {
              let obj = {
                id: item.id,
                quantity: 1,
              };
              contents.push(obj);
              content_ids.push(item.id);
              content_name.push(item.title);
            });
            window.fbq("track", "ViewContent", {
              content_ids: content_ids,
              contents: contents,
              content_name: content_name.join(","),
              content_category: category.title,
              currency: "USD",
              content_type: "product_group",
            });
            let customdata = {
              content_ids: content_ids,
              contents: contents,
              content_name: content_name.join(","),
              content_category: category.title,
              currency: "USD",
              content_type: "product_group",
            };
            let userdata = {
              em: "",
              ph: "",
              fn: "",
              ln: "",
              ct: "",
              st: "",
              zp: "",
              country: "",
              external_id: "",
              client_ip_address: "",
              client_user_agent: "",
              fbc: "",
              fbp: "",
              lead_id: "",
              anon_id: "",
              madid: "",
              page_id: "",
              page_scoped_user_id: "",
              ctwa_clid: "",
              ig_account_id: "",
              ig_sid: "",
            };
            let event_id = Math.round(Math.random() * 10000000000);
            FackbookAPI.Facebook(
              event_id,
              "ViewContent",
              customdata,
              userdata
            ).then((res) => {
              console.log(res);
            });
          }
        });
      }
    });
  }

  

  handlePageChange(e, page) {
    this.setState({ page: page }, () => {
      const { page, per_page, keywords, category } = this.state;
      this.getProductList(page, per_page, keywords, category.id);
      //自动回顶
      window.scrollTo(0, 0);
    });
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  routeProductDetail(id) {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: `${window.globalRouter.routeProductDetail}/${id}`,
        search: location.search,
      });
  }

  render() {
    const { category, productList, page, count } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="category-content">
          {/* head */}
          <div className="head">
            <img src={BASE_URL + category.bannerImage} alt="" />
            {/* <div className="title">{category.title}</div> */}
          </div>
          {/* body */}
          <div className="product-body">
            {productList.length > 0 ? (
              <div className="have-product">
                <div className="row">
                  {productList.map((item, index) => {
                    return (
                      <div
                        className="col"
                        key={index}
                        onClick={() => this.routeProductDetail(item.id)}
                      >
                        <div className="img-box">
                          <img src={BASE_URL + item.image[0]} alt="" />
                          <div className="discount-box">
                              {item.discount}% OFF
                            </div>
                        </div>
                        <div className="txt-box">{item.title}</div>
                        <div className="star">
                          <Rating name="read-only" value={item.star} readOnly />
                        </div>
                        <div className="price">
                          <span>${item.originalPrice}</span>${item.currentPrice}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="none-product">
                <NoneContent size={250} color="#dbdbdb" />
              </div>
            )}
          </div>
          {/* footer */}
          <div className="product-footer">
            <Pagination
              page={page}
              count={count}
              onChange={this.handlePageChange}
              variant="outlined"
              color="standard"
            />
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Category;
