import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import "../styles/home.scss";
import pic from "../image/pi.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import * as GetCategoryListAPI from "../apis/GetCategoryListAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import FooterBar from "./common/FooterBar";
import AllowRight from "../icons/AllowRight";
import * as ConfigAPI from "../apis/ConfigAPI";
import Loading from "./common/Loading";
import webBanner from "../image/webBanner.png";
import mobileBanner from "../image/mobileBanner.png";
import l1 from "../image/1.png";
import l2 from "../image/2.png";
import l3 from "../image/3.png";
import trustPliot from "../image/trustPliot.png";
import pay from "../image/pay.jpg";
import ship from "../image/ship.png";
import l12 from "../image/12.png";
import bag from "../image/bag.png";
import l4 from "../image/l4.png";
import * as GetTagListAPI from "../apis/GetTagListAPI";
import * as ProductAPI from "../apis/ProductAPI";
import Person from "../icons/Person";
import Rating from "@material-ui/lab/Rating";
import queryString from "query-string";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerList: [],
      categoryList: [],
      loadingShow: false,
      showSafe: false,
      message: [],
      showService: false,
      tag: [],
      litterBanner: "",
    };
    this.routeCategory = this.routeCategory.bind(this);
    this.routeOrder = this.routeOrder.bind(this);
    this.routeAbout = this.routeAbout.bind(this);
  }

  componentDidMount() {
    //自动回顶
    window.scrollTo(0, 0);
    this.toggleLoading();
    GetCategoryListAPI.getCategoryList(1, 100).then((res) => {
      this.toggleLoading();
      var list = res.data.data.list;
      if (list) {
        this.setState({ categoryList: list });
      }
    });
    ConfigAPI.getConfig("bannerImgArr").then((res) => {
      var data = res.data.data;
      if (data) {
        this.setState({ bannerList: data.bannerImgArr });
      }
    });
    GetTagListAPI.getTagList(1, 8, 1).then((res) => {
      console.log(res);
      var data = res.data.data.list;
      let { tag } = this.state;
      console.log(data);
      if (data) {
        data &&
          data.map((item) => {
            let obj = {
              id: item.id,
              title: item.title,
              data: [],
            };
            tag.push(obj);
          });
        this.setState({ tag }, () => {
          tag.map((item, index) => {
            ProductAPI.getProductList(1, 20, "", "", item.id).then((res) => {
              var data = res.data.data.list;
              if (data) {
                data.map((item) => {
                  item.image = JSON.parse(item.image);
                  item.star = Math.round(Math.random() * (5 - 4)) + 4;
                  item.discount = Math.ceil(
                    ((parseInt(item.originalPrice) -
                      parseInt(item.currentPrice)) /
                      parseInt(item.originalPrice)) *
                      100
                  );
                });
                tag[index].data = data;
                this.setState({ tag });
              }
            });
          });
        });
      }
    });
    ConfigAPI.getConfig("littleBanner").then((res) => {
      var data = res.data.data;
      if (data) {
        this.setState({ litterBanner: data.littleBanner });
      }
    });
  }

  routeProductDetail(id) {
    let { history, location } = this.props;
    const search = location.search;

    history &&
      history.push({
        pathname: `${window.globalRouter.routeProductDetail}/${id}`,
        search: location.search,
      });
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  routeCategory(id) {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: `${window.globalRouter.routeCategory}/${id}`,
        search: location.search,
      });
  }

  routeOrder() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: window.globalRouter.routeOrder,
        search: location.search,
      });
  }

  routeAbout() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: window.globalRouter.routeAbout,
        search: location.search,
      });
  }

  render() {
    const { bannerList, categoryList, tag, litterBanner } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="home-page">
          {/* swiper */}
          <div className="swiper-row">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={100}
              slidesPerView={1}
              // autoplay={{ delay: 3000 }}
              navigation
              // pagination={{ clickable: true }}
            >
              {bannerList.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={item.img} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="mobile-swiper">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={100}
              slidesPerView={1}
              // autoplay={{ delay: 3000 }}
              navigation
              // pagination={{ clickable: true }}
            >
              {bannerList.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={item.mobileImg} alt="" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          {/* little banner */}
          <div className="little-banner">
            <img src={webBanner} alt="" />
          </div>
          <div className="mobile-banner">
            <img src={mobileBanner} alt="" />
          </div>
          {/* sec1  category*/}
          <div className="sec1-row">
            <div className="row">
              {categoryList.map((item, index) => {
                return (
                  <div
                    className="col"
                    key={index}
                    onClick={() => this.routeCategory(item.id)}
                  >
                    <div className="img">
                      <img src={BASE_URL + item.image} alt="" />
                    </div>
                    <div className="info">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* banner */}
         
          <div className="litterBanner-row">
          <div className="title-p">Our Story</div>
            <img src={litterBanner} alt="" onClick={this.routeAbout} />
            
          </div>
          {/* 推荐 */}
          {tag.map((item, index) => {
            return (
              <div className="related-row" key={index}>
                <div className="title">{item.title}</div>
                <div className="product-row">
                  {item.data &&
                    item.data.map((tagLi, TagIndex) => {
                      return (
                        <div
                          className="product-li"
                          key={TagIndex}
                          onClick={() => this.routeProductDetail(tagLi.id)}
                        >
                          <div className="img-box">
                            <img src={BASE_URL + tagLi.image[0]} alt="" />
                            <div className="discount-box">
                              {tagLi.discount}% OFF
                            </div>
                          </div>
                          <div className="txt-box">{tagLi.title}</div>
                          <div className="star">
                            <Rating
                              name="read-only"
                              value={tagLi.star || ""}
                              readOnly
                            />
                          </div>
                          <div className="price">
                            <span>${tagLi.originalPrice}</span>$
                            {tagLi.currentPrice}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}

          {/* ship */}
          <div className="ship-row">
            <div className="li">
              <div className="img">
                <img src={ship} alt="" />
              </div>
              <div className="txt">Free Shipping</div>
            </div>
            <div className="li">
              <div className="img">
                <img src={l12} alt="" />
              </div>
              <div className="txt">12-Month Warranty</div>
            </div>
            <div className="li">
              <div className="img">
                <img src={bag} alt="" />
              </div>
              <div className="txt">Free 30-Day Returns</div>
            </div>
            <div className="li">
              <div className="img">
                <img src={l4} alt="" />
              </div>
              <div className="txt">Certified Sellers</div>
            </div>
          </div>
          {/* sec2 */}
          <div className="sec2-row">
            <div className="content">
              <div className="box">
                {" "}
                <div className="info">
                  <div className="title">Your thoughtful shopping market</div>
                  <div className="txt">
                    Our goal is to ensure your satisfaction with every purchase.
                  </div>
                </div>
                <div className="image">
                  <img src={trustPliot} alt="" />
                </div>
              </div>
              <div className="sec2">
                <div className="bgc">
                  <img src={trustPliot} alt="" />
                </div>
                <div className="l1">
                  <div className="head">
                    <img src={l1} alt="" />
                    <div className="txt">Return Policy</div>
                  </div>
                  <div className="detail">
                    We offer a hassle-free return policy for all purchases made
                    through our website. If you are not completely satisfied
                    with your purchase, you may return the item(s) within 30
                    days of receipt for a full refund. Please ensure that the
                    item(s) are unused, in their original packaging, and
                    accompanied by proof of purchase.
                  </div>
                </div>
                <div className="l1">
                  <div className="head">
                    <img src={l2} alt="" />
                    <div className="txt">Exchange Policy</div>
                  </div>
                  <div className="detail">
                    We also offer an exchange policy for eligible items. If you
                    would like to exchange your purchase for a different size,
                    color, or style, please contact us within 14 days of receipt
                    to initiate the exchange process. The item(s) must be in
                    unused condition and in their original packaging.
                  </div>
                </div>
                <div className="l1">
                  <div className="head">
                    <img src={l3} alt="" />
                    <div className="txt">Warranty Policy</div>
                  </div>
                  <div className="detail">
                    All products purchased through our website are covered by a
                    1-year warranty against defects in materials and
                    workmanship. If you encounter any issues with your purchase
                    during the warranty period, please contact us for
                    assistance.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
