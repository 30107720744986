import React from "react";
const Person = ({ viewBox = "0 0 24 24", size = 24, ...props }) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="19765"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 0C229.674667 0 0 229.674667 0 512s229.674667 512 512 512 512-229.674667 512-512S794.325333 0 512 0zM523.221333 152.170667c193.6 0 193.92 148.117333 193.92 231.424s-77.568 238.08-193.92 238.272c-116.352 0.170667-193.92-154.944-193.92-238.250667C329.301333 300.309333 329.642667 152.170667 523.221333 152.170667zM512 995.776c-148.16 0-280.682667-66.688-369.429333-171.584 11.648-32.213333 28.693333-67.114667 51.733333-85.034667 49.536-38.549333 192.298667-102.613333 192.298667-102.613333l90.133333 171.413333 16.384-41.557333-25.408-51.242667 50.794667-51.264 50.773333 51.264-25.365333 51.242667 13.738667 40.597333 92.714667-170.453333c0 0 142.784 64.064 192.32 102.613333 22.570667 17.578667 38.421333 44.864 49.578667 71.722667C803.690667 923.392 666.325333 995.776 512 995.776z"
      fill="#dbdbdb"
      p-id="19766"
    ></path>
  </svg>
);

export default Person;
