import React from "react";

const Fackbook = ({
  color = "#8a8a8a",
  viewBox = "0 0 24 24",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2311"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M745.317906 68.314898l0 142.583392-84.789105 0c-30.971385 0-51.861158 6.475482-62.668295 19.426445-10.807137 12.950963-16.210193 32.387641-16.210193 58.31208l0 102.071723 158.241001 0-21.050432 159.867034L581.650313 550.575574l0 409.91395L416.391479 960.489524 416.391479 550.576597 278.68107 550.576597 278.68107 390.709563l137.709385 0 0-117.731378c0-66.964134 18.723434-118.90511 56.170301-155.820881 37.445844-36.916794 87.312578-55.375192 149.600203-55.375192 52.931536 0 93.983511 2.155083 123.156948 6.464225L745.317906 68.314898 745.317906 68.314898z"
      fill={color}
      p-id="2312"
    ></path>
  </svg>
);

export default Fackbook;
