import React from "react";

const Instagram = ({
    color = "#8a8a8a",
    size= 24,
    viewBox = "0 0 24 24",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="10043"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 270.4c-132.8 0-240 108.8-240 241.6s107.2 241.6 240 241.6S752 644.8 752 512s-107.2-241.6-240-241.6z m0 403.2c-88 0-160-72-160-161.6s72-161.6 160-161.6S672 422.4 672 512s-72 161.6-160 161.6zM752 160c-44.8 0-80 36.8-80 80s35.2 80 80 80 80-36.8 80-80-35.2-80-80-80z"
      p-id="10044"
      fill={color}
    ></path>
    <path
      d="M768 0H256C115.2 0 0 115.2 0 256v507.2C0 907.2 116.8 1024 260.8 1024h504C907.2 1024 1024 907.2 1024 763.2V256c0-140.8-115.2-256-256-256z m176 763.2C944 862.4 864 944 764.8 944H260.8C161.6 944 80 864 80 764.8V256C80 158.4 158.4 80 256 80h512c97.6 0 176 78.4 176 176v507.2z"
      p-id="10045"
      fill={color}
    ></path>
  </svg>
);

export default Instagram;