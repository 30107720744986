import React from "react";
const CloseIcon = ({
  color = "#3E3A39",
  size = 24,
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="8533"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 451.669333l225.834667-225.834666a42.666667 42.666667 0 0 1 60.330666 60.330666l-512 512a42.666667 42.666667 0 1 1-60.330666-60.330666L451.669333 512 225.834667 286.165333a42.666667 42.666667 0 0 1 60.330666-60.330666L512 451.669333z m76.501333 197.162667a42.666667 42.666667 0 0 1 60.330667-60.330667l149.333333 149.333334a42.666667 42.666667 0 0 1-60.330666 60.330666l-149.333334-149.333333z"
      fill={color}
      p-id="8534"
    ></path>
  </svg>
);

export default CloseIcon;
