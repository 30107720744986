import React from "react";

const MenuIcon = ({
    viewBox = "0 0 24 24",
    size = 24,
    color = "#000",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="10712"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M963.764706 180.705882v120.470589H60.235294V180.705882h903.529412zM60.235294 602.352941h903.529412V481.882353H60.235294v120.470588z m0 301.176471h903.529412v-120.470588H60.235294v120.470588z"
      fill={color}
      p-id="10713"
    ></path>
  </svg>
);

export default MenuIcon;