import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import Loading from "./common/Loading";
import "../styles/checkout.scss";
import ReactPhoneInput from "react-telephone-input/lib/withStyles";
import flagImg from "../image/flag.png";
import * as FormValidation from "../utils/FormValidation";
import * as ConfigAPI from "../apis/ConfigAPI";
import { Input } from "reactstrap";
import * as PayAPI from "../apis/PayAPI";
import ReactFlagsSelect from "react-flags-select";
import * as FackbookAPI from "../apis/FacebookAPI";
import pay from "../image/pay.jpg";
import queryString from "query-string";
import Reload from "./common/Reload";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import Cookie from "react-cookies";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      cartArr: [],
      shipArr: [],
      productTotal: 0,
      shipPrice: 0,
      cartTotal: 0,
      isEmail: true,
      isPhone: true,
      isFirstName: true,
      isLastName: true,
      isCountry: true,
      isZip: true,
      isAddress: true,
      isCity: true,
      isProvince: true,
      phone: "",
      email: "",
      firstName: "",
      lastName: "",
      country: "US",
      city: "",
      address: "",
      zip: "",
      note: "",
      province: "",
      courier: "",
      reloadingShow: false,

      // stripe
      clientSecret: "",
      stripePromise: "",
      payment_intent_id: null,
      userDetails: {},
      url: "",
      count: 3,
    };

    this.blurPhone = this.blurPhone.bind(this);
    this.inputPhone = this.inputPhone.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.blurInput = this.blurInput.bind(this);
    this.clickToPay = this.clickToPay.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.toggleReload = this.toggleReload.bind(this);
  }

  componentDidMount() {
    this.renderBigCart();
    this.toggleShow();
    ConfigAPI.getConfig("shipArr").then((res) => {
      this.toggleShow();
      var data = res.data.data;
      if (data) {
        let shipArr = data.shipArr;
        const { productTotal } = this.state;
        shipArr.map((item, index) => {
          if (index === 0) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
        this.setState({
          shipArr,
          shipPrice: shipArr[0].price || 0,
          courier: 0,
          // cartTotal: parseFloat(
          //   parseFloat(productTotal) + parseFloat(shipArr[0].price)
          // ).toFixed(2),
          cartTotal: parseFloat(parseFloat(productTotal)).toFixed(2),
        });
      }
    });
    if (localStorage.getItem("payment_intent_id")) {
      let payment_intent_id = localStorage.getItem("payment_intent_id");
      this.setState({ payment_intent_id: JSON.parse(payment_intent_id) });
    }
    if (Cookie.load("prohibitd_purchase")) {
      console.log("禁止购买------");
      this.setState({ count: 0 });
    }
  }

  blurEmail(e) {
    if (FormValidation.isValidEmail(e.target.value)) {
      this.setState({ isEmail: true });
    } else {
      this.setState({ isEmail: false });
    }
  }

  // phone
  inputPhone(phone) {
    let telephone = phone.replace("-", "").replace(" ", "");
    this.setState({ phone: telephone });
  }

  blurPhone(phone) {
    console.log(phone);
    if (FormValidation.isValidPhoneNumber(phone)) {
      this.setState({ isPhone: true });
    } else {
      this.setState({ isPhone: false });
    }
  }

  inputChange(e, type) {
    switch (type) {
      case "email":
        this.setState({ email: e.target.value, isEmail: true });
        break;
      case "firstName":
        this.setState({ firstName: e.target.value, isFirstName: true });
        break;
      case "lastName":
        this.setState({ lastName: e.target.value, isLastName: true });
        break;
      case "country":
        this.setState({ country: e.target.value, isCountry: true });
        break;
      case "address":
        this.setState({ address: e.target.value, isAddress: true });
        break;
      case "city":
        this.setState({ city: e.target.value, isCity: true });
        break;
      case "zip":
        this.setState({ zip: e.target.value, isZip: true });
        break;
      case "note":
        this.setState({ note: e.target.value });
        break;
      case "province":
        this.setState({ province: e.target.value });
        break;
      default:
        break;
    }
  }

  blurInput(e, type) {
    switch (type) {
      case "email":
        if (!FormValidation.isValidEmail(e.target.value)) {
          this.setState({ isEmail: false });
        } else {
          this.setState({ isEmail: true });
        }
        break;
      case "firstName":
        if (e.target.value.trim() === "") {
          this.setState({ isFirstName: false });
        } else {
          this.setState({ isFirstName: true });
        }
        break;
      case "lastName":
        if (e.target.value.trim() === "") {
          this.setState({ isLastName: false });
        } else {
          this.setState({ isLastName: true });
        }
        break;
      case "country":
        if (e.target.value.trim() === "") {
          this.setState({ isCountry: false });
        } else {
          this.setState({ isCountry: true });
        }
        break;
      case "city":
        if (e.target.value.trim() === "") {
          this.setState({ isCity: false });
        } else {
          this.setState({ isCity: true });
        }
        break;
      case "address":
        if (e.target.value.trim() === "") {
          this.setState({ isAddress: false });
        } else {
          this.setState({ isAddress: true });
        }
        break;
      case "province":
        if (e.target.value.trim() === "") {
          this.setState({ isProvince: false });
        } else {
          this.setState({ isProvince: true });
        }
        break;
      case "zip":
        const reg = /^[a-zA-Z0-9-_]{4,10}$/;
        if (!reg.test(e.target.value)) {
          this.setState({ isZip: false });
        } else {
          this.setState({ isZip: true });
        }
        break;
      default:
        break;
    }
  }

  renderBigCart() {
    let { location } = this.props;
    // let flag = "";
    // if (location.search !== "") {
    //   let arr = queryString.parse(location.search);
    //   console.log(arr);
    //   flag = arr.flag;
    //   console.log(flag);
    // }
    let id = location.pathname.replace(
      `${window.globalRouter.routeCheckout}/`,
      ""
    );

    if (id === "1") {
      console.log("来自立即购买");
      if (localStorage.getItem("shopNow")) {
        let cartArr = JSON.parse(localStorage.getItem("shopNow")) || [];
        let productTotal = 0;
        let goods = [];
        cartArr.map((item) => {
          productTotal = parseFloat(
            parseFloat(productTotal) + parseFloat(item.total)
          ).toFixed(2);
          let skulist = [];
          item.sku.map((i) => {
            let obj = i.selectValue;
            skulist.push(obj);
          });
          const obj = {
            skulist,
            num: item.num,
            id: item.id,
          };
          goods.push(obj);
        });
        this.setState({ cartArr, productTotal, goods }, () => {
          // fbq
          let id = cartArr[0].id;
          let quantity = goods.num;
          let category = cartArr[0].categoryList.title;
          if (window.fbq) {
            window.fbq("track", "InitiateCheckout", {
              content_ids: [id],
              contents: [{ id: id, quantity: quantity }],
              content_category: category,
              currency: "USD",
              num_items: quantity,
              value: productTotal,
            });
            let customdata = {
              content_ids: [id],
              contents: [{ id: id, quantity: quantity }],
              content_category: category,
              currency: "USD",
              num_items: quantity,
              value: productTotal,
            };
            let userdata = {
              em: "",
              ph: "",
              fn: "",
              ln: "",
              ct: "",
              st: "",
              zp: "",
              country: "",
              external_id: "",
              client_ip_address: "",
              client_user_agent: "",
              fbc: "",
              fbp: "",
              lead_id: "",
              anon_id: "",
              madid: "",
              page_id: "",
              page_scoped_user_id: "",
              ctwa_clid: "",
              ig_account_id: "",
              ig_sid: "",
            };
            let event_id = Math.round(Math.random() * 10000000000);
            FackbookAPI.Facebook(
              event_id,
              "InitiateCheckout",
              customdata,
              userdata
            ).then((res) => {
              console.log(res);
            });
          }
        });
      }
    } else {
      if (localStorage.getItem("cartArr")) {
        let cartArr = JSON.parse(localStorage.getItem("cartArr")) || [];
        let productTotal = 0;
        let goods = [];
        let content_ids = [];
        let contents = [];
        let content_category = [];
        let num = 0;
        cartArr.map((item) => {
          productTotal = parseFloat(
            parseFloat(productTotal) + parseFloat(item.total)
          ).toFixed(2);
          {
            item.categoryList && content_category.push(item.categoryList.title);
          }

          let skulist = [];
          item.sku.map((i) => {
            let obj = i.selectValue;
            skulist.push(obj);
          });
          const obj = {
            skulist,
            num: item.num,
            id: item.id,
          };
          const contentItem = {
            quantity: item.num,
            id: item.id,
          };
          num += parseInt(item.num);
          goods.push(obj);
          content_ids.push(obj.id);
          contents.push(contentItem);
        });
        this.setState({ cartArr, productTotal, goods }, () => {
          // fbq
          if (window.fbq) {
            window.fbq("track", "InitiateCheckout", {
              content_ids: content_ids,
              contents: contents,
              content_category: content_category.join(","),
              currency: "USD",
              num_items: num,
              value: productTotal,
            });
            let customdata = {
              content_ids: content_ids,
              contents: contents,
              content_category: content_category.join(","),
              currency: "USD",
              value: productTotal,
              num_items: num,
            };
            let userdata = {
              em: "",
              ph: "",
              fn: "",
              ln: "",
              ct: "",
              st: "",
              zp: "",
              country: "",
              external_id: "",
              client_ip_address: "",
              client_user_agent: "",
              fbc: "",
              fbp: "",
              lead_id: "",
              anon_id: "",
              madid: "",
              page_id: "",
              page_scoped_user_id: "",
              ctwa_clid: "",
              ig_account_id: "",
              ig_sid: "",
            };
            let event_id = Math.round(Math.random() * 10000000000);
            FackbookAPI.Facebook(
              event_id,
              "InitiateCheckout",
              customdata,
              userdata
            ).then((res) => {
              console.log(res);
            });
          }
        });
      }
    }
  }

  changeCheckedValue(index) {
    const { shipArr, productTotal } = this.state;
    shipArr.map((item, i) => {
      item.checked = false;
      if (i === index) {
        item.checked = true;
      }
    });
    this.setState({
      shipArr,
      shipPrice: shipArr[index].price,
      courier: index,
      cartTotal: parseFloat(
        parseFloat(productTotal) + parseFloat(shipArr[index].price)
      ).toFixed(2),
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  setSelected(code) {
    this.setState({ country: code });
  }

  clickToPay() {
    let {
      email,
      address,
      phone,
      city,
      goods,
      country,
      courier,
      firstName,
      lastName,
      zip,
      cartArr,
      province,
      productTotal,
      payment_intent_id,
      count,
    } = this.state;
    // fbq
    if (window.fbq) {
      let content_ids = [];
      let contents = [];
      let content_category = [];
      let num = 0;
      cartArr.map((item) => {
        content_category.push(item.categoryList.title);
        const contentItem = {
          quantity: item.num,
          id: item.id,
        };
        num += parseInt(item.num);
        content_ids.push(contentItem.id);
        contents.push(contentItem);
      });
      window.fbq("track", "AddPaymentInfo", {
        content_ids: content_ids,
        contents: contents,
        content_category: content_category.join(","),
        currency: "USD",
        value: productTotal,
      });
      let customdata = {
        content_ids: content_ids,
        contents: contents,
        content_category: content_category.join(","),
        currency: "USD",
        value: productTotal,
        num_items: num,
      };
      let userdata = {
        em: email,
        ph: phone,
        fn: firstName,
        ln: lastName,
        ct: city,
        st: province,
        zp: zip,
        country: country,
        external_id: "",
        client_ip_address: "",
        client_user_agent: "",
        fbc: "",
        fbp: "",
        lead_id: "",
        anon_id: "",
        madid: "",
        page_id: "",
        page_scoped_user_id: "",
        ctwa_clid: "",
        ig_account_id: "",
        ig_sid: "",
      };
      let event_id = Math.round(Math.random() * 10000000000);
      FackbookAPI.Facebook(
        event_id,
        "AddPaymentInfo",
        customdata,
        userdata
      ).then((res) => {
        console.log(res);
      });
    }

    goods = JSON.stringify(goods);
    if (email !== "") {
      localStorage.setItem("email", JSON.stringify(email));
    }

    let nextBtnDisabled = false;

    nextBtnDisabled =
      email.trim() === "" ||
      city.trim() === "" ||
      country.trim() === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      phone.trim() === "" ||
      firstName.trim() === "" ||
      lastName.trim() === "";

    let { location } = this.props;
    let ad = "";
    if (location.search !== "") {
      let arr = queryString.parse(location.search);
      ad = arr.ad;
      console.log(ad);
    }
    if (localStorage.getItem("ad")) {
      ad = JSON.parse(localStorage.getItem("ad")) || "";
      console.log(ad);
    }
    console.log(location);
    let param = {
      email,
      address,
      telephone: phone,
      city,
      goods,
      country,
      courier,
      code: zip,
      province,
      first_name: firstName,
      last_name: lastName,
      success_url: "/Success",
      cancel_url: "/Fail",
      ad,
      payment_intent_id,
      type: 7,
    };
    if (!nextBtnDisabled) {
      if (count === 0) {
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail:
              "Today's purchase times have arrived,please come back tomorrow!",
          })
        );
        // 24小时内只允许点三次
        let time = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
        let value = "Prohibitd purchase";
        Cookie.save("prohibitd_purchase", value, { expires: time, path: "/" });
        return;
      }
      count--;
      console.log(count);
      this.setState({ count });
      this.toggleReload();
      PayAPI.Pay(param).then((res) => {
        console.log(res);
        this.toggleReload();
        var data = res.data.data.data;
        console.log(data);
        if (data !== null) {
          let stripePromise = loadStripe(data.api_publishable_key);
          let userDetails = {
            name: `${firstName} ${lastName}`,
            email: email,
            address: {
              city: city,
              state: province,
              country: country,
              postal_code: zip,
            },
          };
          this.setState({
            stripePromise,
            payment_intent_id: data.payment_intent_id,
            clientSecret: data.clientSecret,
            userDetails,
            url: `${BASE_URL}/success?payment_intent_id=${data.payment_intent_id}&status=succeeded&c_site_url=${data.c_site_url}&ad=${ad}&orderId=${data.orderId}`,
          });
          localStorage.setItem(
            "payment_intent_id",
            JSON.stringify(data.payment_intent_id)
          );
        } else {
          this.clickToPay();
        }
      }).catch((err)=>{
        
      })
    }
  }

  render() {
    const {
      phone,
      cartArr,
      shipArr,
      productTotal,
      cartTotal,
      isEmail,
      isAddress,
      isCity,
      isCountry,
      isFirstName,
      isLastName,
      isPhone,
      isZip,
      isProvince,
      province,
      email,
      city,
      country,
      address,
      zip,
      lastName,
      firstName,
      selected,
      stripePromise,
      clientSecret,
      userDetails,
      url,
    } = this.state;

    let nextBtnDisabled = false;

    nextBtnDisabled =
      email.trim() === "" ||
      city.trim() === "" ||
      country.trim() === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      phone.trim() === "" ||
      firstName.trim() === "" ||
      lastName.trim() === "";

    const appearance = {
      theme: "stripe",
    };
    const options = {
      clientSecret,
      appearance,
      locale: "en",
    };

    return (
      <React.Fragment>
        <Reload {...this.props} reloadingShow={this.state.reloadingShow} />
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="checkout-content">
          <div className="head">
            <div className="title">Checkout</div>
          </div>
          <div className="checkout-body">
            <div className="left-input">
              {/* email */}
              <div className="row">
                <div className="title">Email</div>
                <input
                  className={`email ${isEmail ? "" : "err-box"}`}
                  type="text"
                  onChange={(e) => this.inputChange(e, "email")}
                  onBlur={(e) => this.blurInput(e, "email")}
                  placeholder="Please enter your Email"
                />
                <span className={`err-span ${isEmail ? "" : "span-show"}`}>
                  * please enter the correct email address
                </span>
              </div>
              {/* Country / Region */}
              <div className="row">
                <div className="title">
                  Country / Region <span>*</span>
                </div>
                <ReactFlagsSelect
                  selected={country}
                  onSelect={(code) => this.setSelected(code)}
                />
                <span className={`err-span ${isCountry ? "" : "span-show"}`}>
                  * please enter a Country
                </span>
              </div>
              {/* name */}
              <div className="half-row">
                <div className="input-box">
                  <div className="title">First Name</div>
                  <input
                    className={isFirstName ? "" : "err-box"}
                    type="text"
                    onChange={(e) => this.inputChange(e, "firstName")}
                    onBlur={(e) => this.blurInput(e, "firstName")}
                  />
                  <span
                    className={`err-span ${isFirstName ? "" : "span-show"}`}
                  >
                    *please enter a Name
                  </span>
                </div>
                <div className="input-box">
                  <div className="title">Last Name</div>
                  <input
                    type="text"
                    className={isLastName ? "" : "err-box"}
                    onChange={(e) => this.inputChange(e, "lastName")}
                    onBlur={(e) => this.blurInput(e, "lastName")}
                  />
                  <span className={`err-span ${isLastName ? "" : "span-show"}`}>
                    *please enter a last name
                  </span>
                </div>
              </div>
              {/* province */}
              <div className="row">
                <div className="title">
                  Province <span>*</span>
                </div>
                <input
                  type="text"
                  className={isProvince ? "" : "err-box"}
                  onChange={(e) => this.inputChange(e, "province")}
                  onBlur={(e) => this.blurInput(e, "province")}
                />
                <span className={`err-span ${isProvince ? "" : "span-show"}`}>
                  * please enter your province
                </span>
              </div>
              {/* Town / City * */}
              <div className="row">
                <div className="title">
                  Town / City <span>*</span>
                </div>
                <input
                  type="text"
                  className={isCity ? "" : "err-box"}
                  onChange={(e) => this.inputChange(e, "city")}
                  onBlur={(e) => this.blurInput(e, "city")}
                />
                <span className={`err-span ${isCity ? "" : "span-show"}`}>
                  * please enter a city
                </span>
              </div>
              {/* Street address */}
              <div className="row">
                <div className="title">
                  Street address <span>*</span>
                </div>
                <input
                  type="text"
                  className={isAddress ? "" : "err-box"}
                  onChange={(e) => this.inputChange(e, "address")}
                  onBlur={(e) => this.blurInput(e, "address")}
                />
                <span className={`err-span ${isAddress ? "" : "span-show"}`}>
                  * please enter your address
                </span>
              </div>

              {/* ZIP Code */}
              <div className="row">
                <div className="title">
                  Zip Code <span>*</span>
                </div>
                <input
                  type="text"
                  className={isZip ? "" : "err-box"}
                  onChange={(e) => this.inputChange(e, "zip")}
                  onBlur={(e) => this.blurInput(e, "zip")}
                />
                <span className={`err-span ${isZip ? "" : "span-show"}`}>
                  * please enter a zip code
                </span>
              </div>
              {/* Phone * */}
              <div className="row">
                <div className="title">
                  Phone <span>*</span>
                </div>
                <ReactPhoneInput
                  className={`phone ${isPhone ? "" : "err-box"}`}
                  defaultCountry="us"
                  value={phone || ""}
                  flagsImagePath={flagImg}
                  onChange={this.inputPhone}
                  onBlur={this.blurPhone}
                />
                <span className={`err-span ${isPhone ? "" : "span-show"}`}>
                  * please enter the correct contact number
                </span>
              </div>
              {/* textarea */}
              <div className="row">
                <div className="title">Order Notes</div>
                <textarea
                  placeholder="Notes about your order, e.g. special notes for delivery."
                  onChange={(e) => this.inputChange(e, "note")}
                ></textarea>
              </div>
            </div>
            <div className="right-order">
              <div className="title">Your Order</div>
              <div className="order-box">
                <div className="title-head">
                  <p>PRODUCT</p>
                  <p>SUBTOTAL</p>
                </div>
                <div className="product">
                  {cartArr.map((item, index) => {
                    return (
                      <div className="li" key={index}>
                        <div className="left">
                          <div className="title">{item.title}</div>
                          ---
                          <div className="sku">
                            {item.sku.map((sku, skuIndex) => {
                              return (
                                <React.Fragment key={skuIndex}>
                                  {skuIndex > 0 ? "," : ""}{" "}
                                  {sku.selectValue.content}
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="num">x {item.num}</div>
                        </div>
                        <div className="price">${item.total}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="subtotal-row">
                  <div className="title">Subtotal</div>
                  <div className="price">${productTotal}</div>
                </div>
                <div className="shipping-row">
                  <div className="title">Shipping</div>
                  <div className="shipArr">
                    <ul>
                      {/* {shipArr.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="tit">
                              {item.title} ${item.price}
                            </div>
                            <Input
                              name="radio1"
                              type="radio"
                              checked={item.checked}
                              onChange={() => this.changeCheckedValue(index)}
                            />
                          </li>
                        );
                      })} */}
                      Free shipping
                    </ul>
                  </div>
                </div>
                <div className="total-row">
                  <div className="title">Total</div>
                  <div className="price">${cartTotal}</div>
                </div>
              </div>
              <div className="tip-box">
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our <a>privacy policy</a>
              </div>
              <div className="image">
                <img src={pay} alt="" />
              </div>
              {clientSecret !== "" ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm userDetails={userDetails} url={url} />
                </Elements>
              ) : (
                <div
                  className={`checkout-btn ${
                    nextBtnDisabled ? "not-check" : ""
                  }`}
                  onClick={this.clickToPay}
                >
                  PROCEED TO PAY
                </div>
              )}
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Checkout;
