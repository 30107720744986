import React from "react";

const SafeIcon = ({ viewBox = "0  0 24 24", size = 24, ...props }) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="7819"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M887.466667 145.066667C729.6 128 610.133333 85.333333 535.466667 19.2l-21.333334-19.2-21.333333 14.933333c-119.466667 85.333333-236.8 128-352 128H106.666667v471.466667c0 145.066667 128 275.2 390.4 401.066667l14.933333 8.533333 14.933333-6.4C785.066667 906.666667 917.333333 772.266667 917.333333 616.533333v-469.333333l-29.866666-2.133333z"
      fill="#40BC8E"
      p-id="7820"
    ></path>
    <path
      d="M362.666667 458.666667c-12.8-14.933333-34.133333-14.933333-46.933334-2.133334-14.933333 12.8-14.933333 34.133333-2.133333 46.933334l128 145.066666c12.8 14.933333 32 23.466667 51.2 23.466667 21.333333 0 40.533333-8.533333 53.333333-25.6l209.066667-256c12.8-14.933333 8.533333-36.266667-4.266667-46.933333-14.933333-12.8-36.266667-8.533333-46.933333 4.266666l-209.066667 256c-4.266667 0-132.266667-145.066667-132.266666-145.066666z"
      fill="#FFFFFF"
      p-id="7821"
    ></path>
  </svg>
);

export default SafeIcon;
