import React from "react";
const ErrorIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#bfbfbf",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="40994"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1017.344 428.544h-81.92c5.12 27.136 8.192 54.784 8.192 83.456 0 238.08-193.024 431.104-431.104 431.104S81.408 750.08 81.408 512 274.432 80.896 512.512 80.896c42.496 0 83.456 6.144 122.368 17.408V14.848C594.944 5.12 553.984 0 512 0 229.376 0 0 229.376 0 512s229.376 512 512 512 512-229.376 512-512c0-28.672-2.56-56.32-6.656-83.456z m0 0"
      p-id="40995"
      fill={color}
    ></path>
    <path
      d="M953.856 165.888L830.976 27.136S640.512 254.464 513.536 404.48c-97.792-118.272-182.272-216.576-182.272-216.576l-102.4 151.04c84.48 50.176 161.792 102.4 230.912 153.088-98.816 159.744-182.272 303.616-179.2 325.12 0 0 85.504-119.808 238.592-281.088 185.344 142.848 281.088 231.424 281.088 231.424 0-21.504-104.448-142.848-225.28-289.792 93.184-94.72 248.32-209.92 378.88-311.808z m0 0"
      p-id="40996"
      fill={color}
    ></path>
  </svg>
);

export default ErrorIcon;
