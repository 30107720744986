import React, { Component } from "react";
import "../styles/success.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import ErrorIcon from "../icons/ErrorIcon";
import CloseIcon from "../icons/CloseIcon";

class Fail extends Component {
  constructor(props) {
    super(props);
    this.routeHome = this.routeHome.bind(this);
    this.routeOrder = this.routeOrder.bind(this);
  }

  routeHome() {
    const { history } = this.props;
    history.push(window.globalRouter.routeHome);
  }

  routeOrder(){
    const { history } = this.props;
    history.push(window.globalRouter.routeOrder);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="pay-content">
          <div className="box">
            <CloseIcon size={20} className="close" onClick={this.routeHome} />
            <ErrorIcon size={30} />
            <p>Fail</p>
            <div className="view" onClick={this.routeOrder}>
              view
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Fail;
