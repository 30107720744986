import React, { Component } from "react";
import "../styles/cart.scss";
import HeaderBar from "./common/HeaderBar";
import Loading from "./common/Loading";
import Number from "./common/Number";
import FooterBar from "./common/FooterBar";
import * as ConfigAPI from "../apis/ConfigAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import CloseIcon from "../icons/CloseIcon";
import { Input } from "reactstrap";
import NoCart from "../icons/NoCart";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartArr: [],
      shipArr: [],
      productTotal: 0,
      shipPrice: 0,
      cartTotal: 0,
      loadingShow: false,
    };
    this.removeProduct = this.removeProduct.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
    this.changeCheckedValue = this.changeCheckedValue.bind(this);
    this.routeHome = this.routeHome.bind(this);
    this.routeCheckout = this.routeCheckout.bind(this);
  }

  numberRef = React.createRef();
  headerRef = React.createRef();

  componentDidMount() {
    this.renderBigCart();
    this.toggleShow();
    ConfigAPI.getConfig("shipArr").then((res) => {
      this.toggleShow();
      var data = res.data.data;
      if (data) {
        let shipArr = data.shipArr;
        const { productTotal } = this.state;
        shipArr.map((item, index) => {
          if (index === 0) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
        this.setState({
          shipArr,
          shipPrice: shipArr[0].price || 0,
          // cartTotal: parseFloat(
          //   parseFloat(productTotal) + parseFloat(shipArr[0].price)
          // ).toFixed(2),
          cartTotal: parseFloat(parseFloat(productTotal)).toFixed(2),
        });
      }
    });
  }

  callBckNumber(num, index) {
    let { cartArr } = this.state;
    cartArr[index].num = num;
    cartArr[index].total = (num * cartArr[index].price).toFixed(2);
    this.setState({ cartArr }, () => {
      localStorage.setItem("cartArr", JSON.stringify(cartArr));
      this.headerRef.current.renderCart();
      this.renderBigCart();
    });
  }

  removeProduct(index) {
    let { cartArr } = this.state;
    cartArr.splice(index, 1);
    this.setState({ cartArr }, () => {
      this.headerRef.current.removeProductFromCart(index);
      localStorage.setItem("cartArr", JSON.stringify(cartArr));
      this.renderBigCart();
    });
  }

  renderBigCart() {
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr")) || [];
      const { shipPrice } = this.state;
      let productTotal = 0;
      cartArr.map((item) => {
        productTotal = (
          parseFloat(productTotal) + parseFloat(item.total)
        ).toFixed(2);
      });
      this.setState({
        cartArr,
        productTotal,
        // cartTotal: parseFloat(
        //   parseFloat(productTotal) + parseFloat(shipPrice)
        // ).toFixed(2),
        cartTotal: parseFloat(parseFloat(productTotal)).toFixed(2),
      });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  routeProductDetail(id) {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: `${window.globalRouter.routeProductDetail}/${id}`,
        // search: search && search !== "" ? `${search}&orderId=${id}` : search,
        search: search,
      });
  }

  changeCheckedValue(index) {
    const { shipArr, productTotal } = this.state;
    shipArr.map((item, i) => {
      item.checked = false;
      if (i === index) {
        item.checked = true;
      }
    });
    this.setState({
      shipArr,
      shipPrice: shipArr[index].price,
      cartTotal: parseFloat(
        parseFloat(productTotal) + parseFloat(shipArr[index].price)
      ).toFixed(2),
    });
  }

  routeHome() {
    let { history, location } = this.props;
    history && history.push({ pathname: "/", search: location.search });
  }

  routeCheckout() {
    let { history, location } = this.props;
    history &&
      history.push({
        pathname: window.globalRouter.routeCheckout,
        search: location.search,
      });
  }

  render() {
    const { cartArr, productTotal, shipArr, cartTotal } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="cart-content">
          <div className="head">
            <div className="title">Shopping Cart</div>
          </div>
          {cartArr.length > 0 ? (
            <div className="cart-body">
              <div className="product-box">
                <div className="header">
                  <div className="tool"></div>
                  <div className="name">PRODUCT</div>
                  <div className="price">PRICE</div>
                  <div className="quantity">QUANTITY</div>
                  <div className="total">TOTAL</div>
                </div>
                {cartArr.map((item, index) => {
                  return (
                    <div className="item-col" key={index}>
                      <div className="tool">
                        <CloseIcon
                          size={18}
                          onClick={() => this.removeProduct(index)}
                          className="icon"
                        />
                      </div>
                      <div className="name">
                        <div className="img">
                          <img src={BASE_URL + item.image[0]} alt="" />
                        </div>
                        <div className="title">{item.title}</div>---{" "}
                        {item.sku.map((k, index) => {
                          return (
                            <div className="sku" key={index}>
                              {index > 0 ? "," : ""}
                              {k.selectValue.content}
                            </div>
                          );
                        })}
                      </div>
                      <div className="price">${item.price}</div>
                      <div className="quantity">
                        <Number
                          num={item.num}
                          {...this.props}
                          ref={this.numberRef}
                          receiveNum={(num) => this.callBckNumber(num, index)}
                        />
                      </div>
                      <div className="total">${item.total}</div>
                    </div>
                  );
                })}
              </div>
              <div className="mini-cart">
                {cartArr.map((item, index) => {
                  return (
                    <div className="mini-row" key={index}>
                      <div className="tool">
                        <CloseIcon
                          size={18}
                          onClick={() => this.removeProduct(index)}
                        />
                      </div>
                      <div className="img-row">
                        <div className="img">
                          <img src={BASE_URL + item.image[0]} alt="" />
                        </div>
                      </div>
                      <div className="detail-row">
                        <div className="title">PRODUCT</div>
                        <div className="name">
                          <div className="title">{item.title}</div>---{" "}
                          {item.sku.map((k, index) => {
                            return (
                              <div className="sku" key={index}>
                                {index > 0 ? "," : ""}
                                {k.selectValue.content}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="price-row">
                        <div className="title">PRICE</div>
                        <div className="price">${item.price}</div>
                      </div>
                      <div className="quantity-row">
                        <div className="title">QUANTITY</div>
                        <Number
                          num={item.num}
                          {...this.props}
                          ref={this.numberRef}
                          receiveNum={(num) => this.callBckNumber(num, index)}
                        />
                      </div>
                      <div className="total-row">
                        <div className="title">TOTAL</div>
                        <div className="total">${item.total}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="check-row">
                <h2>Cart Totals</h2>
                <div className="subtotal-row">
                  <div className="title">Subtotal</div>
                  <div className="subtotal">${productTotal}</div>
                </div>
                <div className="shipping-row">
                  <div className="title">Shipping</div>
                  <div className="shipArr">
                    <ul>
                      {/* {shipArr.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="tit">
                              {item.title} ${item.price}
                            </div>
                            <Input
                              name="radio1"
                              type="radio"
                              checked={item.checked}
                              onChange={() => this.changeCheckedValue(index)}
                            />
                          </li>
                        );
                      })} */}
                      Free shipping
                    </ul>
                  </div>
                </div>
                <div className="total-row">
                  <div className="title">Total</div>
                  <div className="price">${cartTotal}</div>
                </div>
                <div className="checkout" onClick={this.routeCheckout}>
                  CHECKOUT
                </div>
              </div>
            </div>
          ) : (
            <div className="no-product">
              <NoCart size={125} />
              <h1>Your cart is currently empty.</h1>
              <div className="btn" onClick={this.routeHome}>
                RETURN TO SHOP
              </div>
            </div>
          )}
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Cart;
