import React from "react";

const SuccessIcon = ({
    viewBox = "0 0 24 24",
    size = 24,
    color = "#2AA515",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="30823"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M496.64 829.44l-342.016-353.28 88.064-71.68L440.32 562.176c80.896-98.304 261.12-292.864 509.952-447.488l20.48 49.152c-228.352 212.992-414.72 513.024-474.112 665.6z m432.128-386.048c4.096 22.528 5.12 46.08 5.12 68.608 0 233.472-189.44 422.912-422.912 422.912-233.472 0-422.912-189.44-422.912-422.912 0-233.472 189.44-422.912 422.912-422.912 56.32 0 110.592 11.264 158.72 30.72v-84.992c-51.2-17.408-104.448-25.6-158.72-25.6-67.584 0-133.12 13.312-195.584 39.936C257.024 74.752 202.752 111.616 157.696 157.696c-46.08 46.08-81.92 99.328-107.52 159.744-26.624 61.44-39.936 128-39.936 195.584s13.312 133.12 39.936 195.584c25.6 59.392 61.44 113.664 107.52 159.744s99.328 81.92 159.744 107.52c61.44 26.624 128 39.936 195.584 39.936s133.12-13.312 195.584-39.936c59.392-25.6 113.664-61.44 159.744-107.52s81.92-99.328 107.52-159.744c26.624-61.44 39.936-128 39.936-195.584 0-23.552-2.048-46.08-5.12-68.608h-81.92z"
      fill={color}
      p-id="30824"
    ></path>
  </svg>
);

export default SuccessIcon;