import React from "react";

const Tiwtter = ({
    color="#8a8a8a",
    viewBox = "0 0 24 24",
    size = 24,
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2307"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M640.76231111 99.9424c-92.16 33.53031111-150.18666667 119.92177778-143.5648 214.49386667l2.048 36.48853333-36.82986666-4.32355555c-134.01884445-17.15768889-251.20995555-75.09333333-350.56071112-172.88533334l-48.65137778-48.19626666-12.3904 35.80586666c-26.48746667 79.5648-9.54595555 163.84 45.80693334 220.28515556 29.44568889 31.26613333 22.75555555 35.80586667-27.96657778 17.16906666-17.84035555-6.03022222-33.0752-10.56995555-34.55431111-8.2944-5.2224 5.33617778 12.61795555 72.81777778 26.48746667 99.79448889 19.21706667 37.54666667 58.19733333 73.77351111 100.82986666 95.48231111l36.03342222 17.16906667-42.63253333 0.56888889c-41.3696 0-42.84871111 0.79644445-38.2976 16.37262222 14.65457778 48.42382222 72.81777778 99.79448889 137.76213333 122.19733334L300.08888889 757.76l-39.82222222 23.89333333a414.56071111 414.56071111 0 0 1-197.56373334 55.13671112c-33.0752 0.79644445-60.47288889 3.74328889-60.47288888 6.01884444 0 7.28177778 90.14613333 49.10648889 142.08568888 65.70666666 157.01333333 48.19626667 343.40408889 27.30666667 483.10044445-55.1367111C726.81244445 794.57848889 826.27697778 677.50115555 872.88035555 564.33777778c25.03111111-60.30222222 50.13048889-170.3936 50.1304889-223.24337778 0-34.32675555 2.27555555-38.76408889 43.53137777-79.64444445 24.46222222-24.08675555 47.21777778-49.99395555 51.6096-57.50328888 7.2704-14.09706667 6.36017778-14.09706667-31.03857777-1.47911112-62.50951111 22.39146667-71.49795555 19.44462222-40.57315556-14.09706666 22.75555555-23.89333333 50.13048889-67.06062222 50.13048889-79.64444444 0-2.27555555-11.03644445 1.47911111-23.64302223 8.2944-13.30062222 7.50933333-42.63253333 18.64817778-64.85333333 25.2359111l-39.82222222 12.61795556-36.03342222-24.89457778c-19.88835555-13.30062222-47.85493333-28.44444445-62.57777778-32.73386666-37.84248889-10.68373333-95.14097778-9.3184-129.1264 2.73066666"
      p-id="2308"
      fill={color}
    ></path>
  </svg>
);
export default Tiwtter;