import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import Loading from "./common/Loading";
import "../styles/orderDetail.scss";
import * as GetOrderDetailAPI from "../apis/GetOrderDetailAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import ship from "../image/ship.png";
import Dian from "../icons/Dian";

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      order: {},
      shipStatus: "",
      trackingNumber: "",
    };
  }

  componentDidMount() {
    let { location } = this.props;
    console.log(location.pathname);
    let id = location.pathname.replace(
      `${window.globalRouter.routeOrderDetail}/`,
      ""
    );
    if (id) {
      this.toggleLoading();
      GetOrderDetailAPI.GetOrderDetail(id).then((res) => {
        this.toggleLoading();
        console.log(res);
        var data = res.data.data;
        console.log(data);
        if (data) {
          data.createtime = new Date(data.createtime * 1000).toLocaleString();
          data.goodsList.map((item) => {
            item.goods_image = JSON.parse(item.goods_image);
            item.goods_sku = JSON.parse(item.goods_sku);
          });
          // let shipStatus = "";
          // if (data.deliveryList.length > 0) {
          //   let deliveryList = data.deliveryList;
          //   shipStatus = deliveryList[deliveryList.length - 1].deliverystatus;
          // }
          this.setState({
            order: data,
            trackingNumber: data.tracking_number || "",
          });
          console.log(data);
        }
      });
    }
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }
  render() {
    const { order, trackingNumber } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="orderDetail-page">
          <div className="head">
            <div className="title">OrderDetail</div>
          </div>
          <div className="orderDetail-body">
            <div className="order-number">#{order.orderNu}</div>
            {order.goodsList &&
              order.goodsList.map((item, index) => {
                return (
                  <React.Fragment>
                    <div className="order-li" key={index}>
                      <div className="img">
                        <img src={BASE_URL + item.goods_image} alt="" />
                      </div>
                    </div>
                    <div className="li">
                      <div className="info">
                        <div className="title">{item.goods_title}</div>
                        {item.goods_sku.map((sku, skuIndex) => {
                          return (
                            <div className="sku" key={skuIndex}>
                              {sku.title} : {sku.content}
                            </div>
                          );
                        })}
                        <div className="num">num :{item.goods_num}</div>
                      </div>
                      <div className="price">${item.goods_price}</div>
                    </div>
                  </React.Fragment>
                );
              })}
            <div className="detail">
              <div className="title">Freight</div>
              {/* <div className="price">${order.courierfee}</div> */}
              <div className="price">Free shipping</div>
            </div>

            <div className="detail">
              <div className="title">Shipping Address</div>
              <div className="address">
                <li>{order.email}</li>
                <li>
                  {order.first_name} {order.last_name}
                </li>

                <li>
                  {" "}
                  {order.country},{order.city}
                </li>
                <li>{order.address}</li>
                <li> {order.telephone}</li>
              </div>
            </div>
            <div className="detail">
              <div className="title">Total</div>
              <div className="total">${order.goodprice}</div>
            </div>
            <div className="detail">
              <div className="title">Create Time</div>
              <div className="time">{order.createtime}</div>
            </div>
            <div className="detail">
              <div className="title">State</div>
              <div className={order.state === 0 ? "cancel" : "pay"}>
                {order.state === 0 ? "cancel" : "Payed"}
              </div>
            </div>
            <div className="delivery">
              <div className="title">
                {trackingNumber !== "" && (
                  <a
                    href={`https://www.grmtoexpress.com/?trackId=${trackingNumber}`}
                    target="_blank"
                  >
                    {trackingNumber}
                  </a>
                )}
                <img src={ship} alt="" />
              </div>
              <div className="ship-body"></div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default OrderDetail;
