import React, { Component } from "react";
import "../styles/success.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import SuccessIcon from "../icons/SuccessIcon";
import CloseIcon from "../icons/CloseIcon";
import * as FacebookAPI from "../apis/FacebookAPI";
import queryString from "query-string";
import * as GetOrderDetailAPI from "../apis/GetOrderDetailAPI";
import * as CustomStatusAPI from "../apis/CustomStatusAPI";
import axios from "axios";

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 0,
    };
    this.routeHome = this.routeHome.bind(this);
    this.routeOrder = this.routeOrder.bind(this);
  }

  routeHome() {
    const { history } = this.props;
    history.push(window.globalRouter.routeHome);
  }
  routeOrder() {
    const { history } = this.props;
    history.push(window.globalRouter.routeOrder);
  }

  componentDidMount() {
    let { num } = this.state;
    let { location } = this.props;
    if (location.search !== "") {
      let arr = queryString.parse(location.search);
      let id = arr.orderId;
      let orderDetail = "";
      id &&
        GetOrderDetailAPI.GetOrderDetail(id).then((res) => {
          var data = res.data.data;
          if (data) {
            console.log(data);
            orderDetail = data;
            console.log(orderDetail);
            let goodList = data.goodsList;
            let content_ids = [];
            let contents = [];
            let content_name = [];
            let num = 0;
            if (window.fbq) {
              goodList.map((item) => {
                let obj = {
                  id: item.goods_id,
                  quantity: item.goods_num,
                };
                contents.push(obj);
                content_ids.push(item.goods_id);
                num += parseInt(item.goods_num);
                content_name.push(item.goods_title);
              });
              window.fbq("track", "Purchase", {
                content_ids: content_ids,
                contents: contents,
                content_name: content_name.join(","),
                value: data.goodprice,
                currency: "USD",
                content_type: "product",
                num_items: num,
              });
              let customdata = {
                content_ids: content_ids,
                contents: contents,
                content_name: content_name.join(","),
                value: data.goodprice,
                currency: "USD",
                content_type: "product",
                num_items: num,
              };
              let userdata = {
                em: data.email,
                ph: data.telephone,
                fn: data.first_name,
                ln: data.last_name,
                ct: data.city,
                st: data.province,
                zp: data.code,
                country: data.country,
                external_id: "",
                client_ip_address: "",
                client_user_agent: "",
                fbc: "",
                fbp: "",
                lead_id: "",
                anon_id: "",
                madid: "",
                page_id: "",
                page_scoped_user_id: "",
                ctwa_clid: "",
                ig_account_id: "",
                ig_sid: "",
              };
              let event_id = Math.round(Math.random() * 10000000000);
              let orderNu = data.orderNu;
              FacebookAPI.Facebook(
                event_id,
                "Purchase",
                customdata,
                userdata,
                orderNu
              ).then((res) => {
                console.log(res);
              });
            }
          }
        });
      if (num === 0) {
        console.log('num=0-----------------');
        this.setState({ num: num + 1 }, () => {
          CustomStatusAPI.customStatus(
            arr.payment_intent_id,
            arr.status,
            arr.c_site_url
          ).then((res) => {
            var data = res.data.data;
            if (data.status === "succeeded") {
              axios
                .post(
                  "https://www.hsmail.vip/EmailTemplate/GetTemplate",
                  {},
                  {
                    params: {
                      id: 12,
                    },
                  }
                )
                .then((res) => {
                  console.log(res);
                  var data = res.data.data;
                  console.log(data);
                  if (data) {
                    axios
                      .post(
                        "https://www.hsmail.vip/api/External/SingleEmailSend",
                        {
                          emailTemplateId: data.id,
                          locating: data.locating,
                          replace: `${orderDetail.first_name} ${orderDetail.last_name},${orderDetail.goodsList[0].goods_title}`,
                          subjectlocating: data.subjectlocating,
                          subjectreplace: `${orderDetail.first_name} ${orderDetail.last_name}`,
                          toEmailUser: orderDetail.email,
                        }
                      )
                      .then((res) => {
                        console.log(res);
                        localStorage.removeItem("payment_intent_id");
                      });
                  }
                });
            }
          });
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="pay-content">
          <div className="box">
            <CloseIcon size={20} className="close" onClick={this.routeHome} />
            <SuccessIcon size={30} />
            <p>Success</p>
            <div className="view" onClick={this.routeOrder}>
              view
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Success;
